import React, { useEffect } from 'react';

import { formatCurrency } from '../../../../../../utilities/helpers';

import Button from 'react-bootstrap/Button'

export default function Ticket({ onMouseOver, ticket, ticketId, handleNext, ticketFilters, listing, hasScroll }) {
    let ticketPrice;
    let totalTicketPrice;
    let ticketName;
    let ticketType;
    let ticketFee;
    let timeout;

    useEffect(() => {
        // Some actions
    }, [ticket])

    if (ticket) {
        ticketPrice = ticket?.pricing?.ticketCost;
        ticketName = ticket?.pricing?.ticketName;
        ticketType = ticket?.pricing?.ticketType;
        ticketFee = ticket?.pricing?.totalFees;
        totalTicketPrice = ticket?.pricing?.totalDue;
    }

    if (listing) {
        ticketPrice = listing?.pricing?.ticketCost;
        ticketName = listing?.pricing?.ticketName;
        ticketType = `Resale ${listing?.tickets.length} ${listing?.tickets.length > 1 ? 'Tickets' : 'Ticket'}`;
        ticketFee = listing?.pricing?.totalFeesWithProcessingFee;
        totalTicketPrice = listing?.pricing?.ticketCostWithFeesAndTax;
    }

    const mobileDesktopMouseEnter = () => {
        if (window.innerWidth <= 992) return;
        onMouseOver('selection', ticket, null);
    }

    const mobileDesktopMouseLeave = () => {
        if (window.innerWidth <= 992) return;
        onMouseOver('selection', null, null);
    }

    const handleTouchStart = () => {
        if (window.innerWidth >= 992) return;
        timeout = setTimeout(() => {
            onMouseOver('selection', ticket, null);
        }, 500);
    }

    const handleTouchEnd = () => {
        clearTimeout(timeout);
    }

    return (
        <li
            onMouseEnter={() => mobileDesktopMouseEnter()}
            onMouseLeave={() => mobileDesktopMouseLeave()}
            onTouchStart={() => handleTouchStart()}
            onTouchEnd={() => handleTouchEnd()}
            onClick={() => handleNext(ticket ? ticket : null, listing ? listing : null)}
            className={`ticket ticket-info-row ${hasScroll && ticket?.id === ticketId ? 'scrollSelected' : ''}`}>
            <div>
                <div>
                    <span className="ticket-name">{ticketName}</span>
                </div>
                <div>
                    <span className="text-muted caption lh-20">{ticketType}</span>
                </div>
            </div>
            <div className="ticket-price">
                {!ticket?.locked ? (
                    <>
                        <div>
                            <span className="total">
                                {formatCurrency(totalTicketPrice)}
                            </span>
                        </div>
                        <div>
                            <span className="tax-fees">
                                Incl. Taxes and Fees
                            </span>
                        </div>
                    </>
                ) : (
                    <Button
                        variant="default"
                        size="sm"
                        className="btn-unlock mt-0">
                        Unlock
                    </Button>
                )}
            </div>
        </li>
    );
}
