import React, { Fragment, useState, useEffect, useContext } from 'react';

import LoadingContext from '../../../context/Loading/Loading';
import authService from '../../../utilities/services/auth.service';

import { updatePersonalDetails } from '../../../utilities/api';
import { emailPatternMatch, namePatternMatch } from '../../../utilities/helpers';

import Alert from 'react-bootstrap/Alert';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';

import { PageLoadingContainer } from '../../PageLoadingContainer';
import { Spinner } from "../../LoadingContainer/Spinner"
import { Error } from "../../Error"

export default function PersonalDetailsForm() {

    const { isLoading, showLoading, hideLoading } = useContext(LoadingContext)

    let user = sessionStorage.getItem('user');
    user = JSON.parse(user).user;

    const [initialState, setInitialState] = useState()

    const [
        formValid,
        setFormValid
    ] = useState(false);

    const [isEmailValid, setIsEmailValid] = useState(true)

    const [
        formData,
        setFormData
    ] = useState({});

    const [
        firstName,
        setFirstName
    ] = useState(user.firstName);

    const [
        lastName,
        setLastName
    ] = useState(user.lastName);

    const [
        email,
        setEmail
    ] = useState(user.email);

    const [isDisabled, setIsDisabled] = useState(true);

    const [isSaving, setIsSaving] = useState(false)

    const [show, setShow] = useState(false);

    useEffect(() => {
        showLoading()
        setInitialState({
            firstName,
            lastName,
            email
        })

        if (firstName && lastName && email) {
            setFormValid(true);
            hideLoading()
        }
        else {
            setFormValid(false);
            hideLoading()
        }
    }, []);

    // check if form is valid 
    useEffect(
        () => {
            setFormValid((firstName !== "" && lastName !== "" && email !== "" && isEmailValid));
        },
        [
            firstName,
            lastName,
            email,
            isEmailValid
        ]
    );

    // reset error when input changes
    useEffect(() => {
        if (!isEmailValid) {
            setIsEmailValid(true)
        }
    }, [email])


    useEffect(() => {
        if (formValid && (initialState?.email !== email || initialState?.firstName !== firstName || initialState?.lastName !== lastName)) {
            setIsDisabled(false)
        } else setIsDisabled(true)
    }, [formValid, initialState, firstName, lastName, email])

    const validatePattern = (val) => {
        const isMatch = emailPatternMatch(val)
        setIsEmailValid(isMatch)
    }

    const submitForm = () => {
        setIsSaving(true)
        let data = {
            data: {
                email,
                firstName,
                lastName
            }
        };

        updatePersonalDetails(data).then((res) => {
            authService.setUser(res.data);
            setShow(true)
            setIsSaving(false)

            setInitialState({
                firstName,
                lastName,
                email
            })
        }).catch((err) => {
            console.error(err)
            setIsSaving(false)
        });
    };

    const notificationModal = () => {
        if (show) {
            return (
                <Alert variant="success" className="mb-3" onClose={() => setShow(false)} dismissible>
                    <p>
                        Your Personal Details have successfully been updated.
                    </p>
                </Alert>
            );
        }
    }

    return (
        <Fragment>
            {isLoading ? (
                <PageLoadingContainer />
            ) : (
                <Form className="d-flex-column">
                    {notificationModal()}
                    <Form.Group className="form-group" controlId="email">
                        <Form.Label>Email</Form.Label>
                        <Form.Control
                            type="email"
                            name="email"
                            placeholder="Enter your email"
                            required
                            onBlur={(e) => validatePattern(e.target.value)}
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            className={email && !isEmailValid && 'error-border'}
                        />
                        {email && !isEmailValid && (
                            <Error type="invalidEmail" val={email} />
                        )}
                    </Form.Group>

                    <Form.Group className="form-group" controlId="name">
                        <Form.Label>First Name</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder="Enter your first name"
                            name="firstName"
                            required
                            pattern={namePatternMatch}
                            value={firstName}
                            onChange={(e) => setFirstName((name) => e.target.validity.valid || e.target.value === '' ? e.target.value : name)}
                        />
                    </Form.Group>
                    <Form.Group className="form-group" controlId="name">
                        <Form.Label>Last Name</Form.Label>
                        <Form.Control
                            type="text"
                            name="lastName"
                            placeholder="Enter your last name"
                            required
                            pattern={namePatternMatch}
                            value={lastName}
                            onChange={(e) => setLastName((name) => e.target.validity.valid || e.target.value === '' ? e.target.value : name)}
                        />
                    </Form.Group>
                    <Button disabled={isDisabled || isSaving} size="lg" className="icon-button" onClick={submitForm}>
                        {isSaving ? (
                            <Spinner />
                        ) : (
                            'Update'
                        )}
                    </Button>
                </Form>
            )}
        </Fragment>
    );
}
