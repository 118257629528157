import React from 'react';

import { SettingsOptions } from '../../../components';

export default function SettingsOptionsPage() {
	return (
		<>
		<h1 className="heading-sm heading-font">Settings</h1>
		<SettingsOptions />
		</>
	);
}
