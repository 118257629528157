import React, { useRef, useState, useEffect, useContext } from 'react';
import moment from 'moment';

import LoadingContext from '../../context/Loading/Loading';
import authService from '../../utilities/services/auth.service';

import { getMyEvents, getIncomingTransfers, acceptIncomingTransfers, getGuestList } from '../../utilities/api';

import { PageLoadingContainer } from '../PageLoadingContainer';
import { EmptyContainer } from '../EmptyContainer';
import { SwiperNavigationButtons } from '../SwiperNavigationButtons';
import { MyEventsSlider } from './MyEventsSlider';

export default function MyEventsWrapper() {

    const { isLoading, showLoading, hideLoading } = useContext(LoadingContext)

    const { getUser } = authService;
    const user = getUser().user;

    const navigationPrevRef = useRef(null);
    const navigationNextRef = useRef(null);

    const [
        orders,
        setOrders
    ] = useState([]);

    const [
        transfers,
        setTransfers
    ] = useState([]);

    const [isAccepting, setIsAccepting] = useState({
        accepting: false,
        id: null
    })

    const [
        guestLists,
        setGuestLists
    ] = useState([]);

    const getMyTickets = () => {
        getMyEvents()
            .then((res) => {
                let keys = Object.keys(res.data)
                let arr = []
                for (let key of keys) {
                    let ticketsArr = []
                    let hash = {}
                    let data = res.data[key]
                    data.map((d) => d.tickets.map((t) => ticketsArr.push(t)))
                    hash['event'] = data.find((d) => d.event !== null).event
                    hash['tickets'] = ticketsArr
                    arr.push(hash)
                }
                // filter out past events
                let formattedOrders = arr.filter((order) => moment(order.event?.start).isSameOrAfter(moment(), 'day'))
  
                setOrders(formattedOrders);

                setIsAccepting({
                    accepting: false,
                    id: null
                })
                getIncomingTransfers()
                    .then((res) => {
                        setTransfers(res.data)
                    }).catch((err) => {
                        console.log(err)
                        hideLoading()
                    })
                getGuestList(encodeURIComponent(user?.phoneNumber), encodeURIComponent(user?.email))
                    .then((res) => {
                        setGuestLists(res.data)
                        hideLoading()
                    })
                    .catch((err) => {
                        console.error(err)
                        hideLoading()
                    })
            })
            .catch((err) => {
                console.error(err)
                hideLoading();
            });

    };

    useEffect(() => {
        showLoading();
        getMyTickets();
    }, []);

    const acceptTransfer = (transfer) => {
        // save id of transfer 
        setIsAccepting({
            accepting: true,
            id: transfer.id
        })
        let data = {
            transferId: transfer.id
        };
        acceptIncomingTransfers(data).then((res) => {
            getMyTickets()
        }).catch((err) => {
            console.error(err)
            setIsAccepting({
                accepting: false,
                id: null
            })
        });
    };

    return (
        <>
            {isLoading ? (
                <PageLoadingContainer />
            ) : (
                <section className="spacer-xs">
                    <div className="section-heading-sm">
                        <h1>My events</h1>
                        <div className="tablet-desktop-only">
                            <SwiperNavigationButtons navigationPrevRef={navigationPrevRef} navigationNextRef={navigationNextRef} />
                        </div>
                    </div>
                    {((orders.length > 0 && orders?.some(o => o?.tickets?.length > 0)) || transfers.length > 0 || guestLists.length > 0) ? (
                        <MyEventsSlider navigationPrevRef={navigationPrevRef} navigationNextRef={navigationNextRef} orders={orders} transfers={transfers} acceptTransfer={acceptTransfer}
                            isAccepting={isAccepting} guestLists={guestLists} />
                    ) : (
                        <EmptyContainer>
                            <h1>You do not have any events</h1>
                        </EmptyContainer>
                    )}

                </section>
            )}
        </>
    );
}
