import React, { useState } from 'react';
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

import { FlipCard } from '../FlipCard';
import { FlipCardModal } from '../FlipCardModal';
import FlipCardModalBody from '../FlipCardModal/FlipCardModalBody';

import SEATMAP from "../../../assets/animations/SEATMAP.json"
import seasonPass from "../../../assets/animations/season-pass.json"
import flexPack from "../../../assets/animations/flexpack.json"
import availability from "../../../assets/animations/availability.json"
import ticketScan from "../../../assets/animations/ticket-scan.json"
import paymentOptions from "../../../assets/animations/payment-options.json"
import dollars from "../../../assets/animations/dollars.json"
import coins from "../../../assets/animations/coins.json"
import message from "../../../assets/animations/message.json"
import creditCardChip from "../../../assets/animations/credit-card-chip.json"
import chartCol from "../../../assets/animations/chart-column.json"
import noFeesMobile from "../../../assets/animations/nofees-mobile.json"
import noFees from "../../../assets/animations/no-fees.json"

import './feature-cards.scss';

export default function FeatureCards() {

    // -1 overlay not visible
    // Any other number corresponding to a cardId
    // means it is visible, plus then we set the card to have z-index-1001
    const [cardId, setCardId] = useState(-1);
    const [showModal, setShowModal] = useState(false);
    const [modalContent, setModalContent] = useState({ title: '', text: '' });

    const toggleOverlay = (id) => {
        setCardId(id);
    };
    const toggleModal = () => {
        setShowModal(!showModal);
    }

    const cards = [
        {
            id: "1",
            front: {
                title: "Effortless Seat Map Creation.",
                subtitle: "Real-time inventory management."
            },
            back: {
                title: 'Seamless Seat Map Creation and Inventory Management.',
                text: 'Our platform allows you to effortlessly create events with detailed seat maps and manage your inventory efficiently. Design your seating arrangements, assign tickets to pricing levels, holds, and kills, and keep track of your inventory in real-time. Ensure your event runs smoothly by maintaining full control over your seating options, providing a better experience for your attendees.'
            }
        },
        {
            id: "2",
            front: {
                title: "Season & Group Ticketing.",
            },
            back: {
                title: 'Sell Bulk Tickets with Ease.',
                text: 'Maximize your revenue and fan engagement with our all-in-one ticketing platform. Offer season ticket packages for dedicated fans who want the best seats all season long, and provide custom group ticketing options with special pricing for large groups. Our platform makes it easy to manage inventory, create packages, and offer exclusive benefits, ensuring a seamless experience for both your team and your attendees.'
            }
        },
        {
            id: "3",
            front: {
                title: "Flex Packs.",
                subtitle: "Customize your game day experience. "
            },
            back: {
                title: 'Can\'t Make it To Every Game?',
                text: 'Build your own Flex Pack! Choose from a minimum number of games, select your preferred seat locations, and decide how many tickets you need. Enjoy the excitement of live events on your own schedule with flexible options that fit your lifestyle.'
            }
        },
        {
            id: "4",
            front: {
                title: "Access to 24/7 Customer Support.",
            },
            back: {
                title: 'Your Assigned Dedicated Customer Support Rep.',
                text: 'Enjoy personalized service with your own dedicated customer support representative. Our team is committed to providing tailored assistance to ensure your event runs smoothly. Benefit from expert guidance and prompt resolutions, making your event planning and execution seamless and stress-free.'
            }
        },
        {
            id: "5",
            front: {
                title: "Zero Fraud.",
                subtitle: "Protection from bots and scalpers."
            },
            back: {
                title: 'Our Security isn\'t Just Robust — it\'s Evolved.',
                text: 'Our advanced security measures set a new standard in ticketing protection. With CAPTCHA Challenges and Rate Limiting, we ensure every ticket sold is genuine. We\'ve further enhanced security by requiring account verification with a government-issued ID for high-demand events, adding an extra layer of defence against fraud. This comprehensive approach effectively blocks sophisticated bots and fraudulent ticketing schemes, making ticket fraud a thing of the past. Choose us for a secure and trustworthy ticketing experience.'
            }
        },
        {
            id: "6",
            front: {
                title: "2-Click Checkout."
            },
            back: {
                title: 'Pay with Ease.',
                text: 'Fans can enjoy a hassle-free checkout experience with our integrated 2-click system, designed to maximize conversion rates and optimize ticket sales. Our streamlined process supports multiple payment options, including credit cards, Apple Pay, and Google Pay, ensuring convenience and flexibility for all users.'
            }
        },
        {
            id: "7",
            front: {
                title: "New Revenue from Custom Offerings.",
                subtitle: 'Capture additional revenue with custom offerings like group sales, F&B add-ons, and exclusive experiences.',
                highlightText: ['Revenue', 'Offerings']
            },
            back: {
                title: 'Increased Income.',
                text: 'Our ticketing system is designed to benefit event organizers at every turn. Capture additional revenue with custom offerings like group sales, F&B add-ons, and exclusive experiences. Maximize your earnings by providing unique, tailored options for your attendees, ensuring an enhanced event experience and increased profitability.'
            }
        },
        {
            id: "8",
            front: {
                title: "Manage Your Facility Fees."
            },
            back: {
                title: 'Boost Revenue with Facility Fees.',
                text: 'Our platform allows you to add a facility fee to your tickets, generating additional revenue that goes straight into your pocket. This feature helps offset operational costs and increases your profitability, all while maintaining a seamless purchasing experience for your fans. Maximize your earnings with each ticket sold by leveraging our flexible facility fee structures. '
            }
        },
        {
            id: "9",
            front: {
                title: "Easily Transfer or Sell Tickets.",
                highlightText: ['Transfer', 'Sell']
            },
            back: {
                title: 'Can\'t Attend an Event? No Problem!',
                text: 'Say goodbye to the hassle of posting ads on social media or arranging meetings with strangers at random locations for ticket exchanges. Now, selling or transferring your tickets is as simple as a single click! This streamlined process provides a smooth, secure, and hassle-free experience, whether you\'re passing tickets to a friend or selling them to a fellow fan.'
            }
        },
        {
            id: "10",
            front: {
                title: "Integrated POS System.",
                label: 'Coming soon'
            },
            back: {
                title: 'Manage Your Concessions on One Platform.',
                text: 'Providing a POS system for teams to use and leverage for advanced concession sales, such as selling a ticket with a hot dog, tracking sales, and minimizing theft by going cashless. With every purchase, gain valuable insights into your fans\' preferences and behaviours, allowing you to tailor future offerings and create personalized experiences. This seamless integration helps you understand your audience better, boosting engagement and loyalty.'
            }
        },
        {
            id: "11",
            front: {
                title: "Unleash the Power of Data."
            },
            back: {
                title: 'Own Your Data, Own Your Event.',
                text: 'Gain exclusive access to a comprehensive database that empowers you to direct email past customers, boosting sales and providing unique opportunities to engage with known fans. Our holistic event and fan data, including primary and resale ticket and market information, allows you to enhance your event experiences, refine your marketing strategy, and build a loyal fan community.'
            }
        },
        {
            id: "12",
            front: {
                title: "All-in Pricing.",
                subtitle: 'No hidden fees, no surprises at checkout.'
            },
            back: {
                title: 'No Hidden Fees!',
                text: 'We believe in transparency and simplicity. When you choose us for ticketing your event, you can rest assured that your fans will encounter no hidden fees at checkout. Our commitment to all-in pricing ensures a clear and straightforward cost structure. This approach guarantees a hassle-free and transparent ticketing experience, dedicated to the success of your event without surprising your fans with unexpected costs.'
            }
        }
    ]
    return (
        <section className='spacer feature-cards'>
            <FlipCardModal modalOpen={showModal} handleClose={toggleModal} headingText={modalContent.title}>
                <FlipCardModalBody textContent={modalContent.text}></FlipCardModalBody>
            </FlipCardModal>
            <Row className='info-container'>
                <div className={`card-overlay ${cardId !== -1 ? 'fade-in' : ''}`}></div>
                <Col xs={12} className={`${cardId == 0 ? 'z-index-998' : 'z-index-neutral'}`}>
                    <FlipCard toggleOverlay={toggleOverlay} toggleModal={toggleModal} setModalContent={setModalContent} showModal={showModal} cardId={0} textAlign="center" obj={cards[0]} animation={SEATMAP} variant="dark-gray" id="map-creation" />
                </Col>
                <Col lg={6} className={`${cardId == 1 ? 'z-index-998' : 'z-index-neutral'}`}>
                    <FlipCard toggleOverlay={toggleOverlay} toggleModal={toggleModal} setModalContent={setModalContent} showModal={showModal} cardId={1} obj={cards[1]} animation={seasonPass} variant="tertiary-light" id="season-pass" />
                </Col>
                <Col lg={6} className={`${cardId == 2 ? 'z-index-998' : 'z-index-neutral'}`}>
                    <FlipCard toggleOverlay={toggleOverlay} toggleModal={toggleModal} setModalContent={setModalContent} showModal={showModal} cardId={2} obj={cards[2]} animation={flexPack} variant="darker" id="flex-packs" />
                </Col>
                <Col xs={12} className={`${cardId == 3 ? 'z-index-998' : 'z-index-neutral'}`}>
                    <FlipCard toggleOverlay={toggleOverlay} toggleModal={toggleModal} setModalContent={setModalContent} showModal={showModal} cardId={3} textAlign="center" obj={cards[3]} animation={availability} variant="secondary" id="support" />
                </Col>
                <Col lg={6} className={`${cardId == 4 ? 'z-index-998' : 'z-index-neutral'}`}>
                    <FlipCard toggleOverlay={toggleOverlay} toggleModal={toggleModal} setModalContent={setModalContent} showModal={showModal} cardId={4} obj={cards[4]} animation={ticketScan} variant="muted" id="ticket-scan" textVariant="success" />
                </Col>
                <Col lg={6} className={`${cardId == 5 ? 'z-index-998' : 'z-index-neutral'}`}>
                    <FlipCard toggleOverlay={toggleOverlay} toggleModal={toggleModal} setModalContent={setModalContent} showModal={showModal} cardId={5} obj={cards[5]} animation={paymentOptions} id="checkout" variant="darker" subtitleSize="sm" />
                </Col>
                <Col xs={12} className={`${cardId == 6 ? 'z-index-998' : 'z-index-neutral'}`}>
                    <FlipCard toggleOverlay={toggleOverlay} toggleModal={toggleModal} setModalContent={setModalContent} showModal={showModal} cardId={6} obj={cards[6]} animation={dollars} textAlign="center" id="revenue" variant="light" subtitleSize="sm" highlightVariant="success" />
                </Col>
                <Col lg={6} className={`${cardId == 7 ? 'z-index-998' : 'z-index-neutral'}`}>
                    <FlipCard toggleOverlay={toggleOverlay} toggleModal={toggleModal} setModalContent={setModalContent} showModal={showModal} cardId={7} obj={cards[7]} animation={coins} variant="pale" id="facility-fees" />
                </Col>
                <Col lg={6} className={`${cardId == 8 ? 'z-index-998' : 'z-index-neutral'}`}>
                    <FlipCard toggleOverlay={toggleOverlay} toggleModal={toggleModal} setModalContent={setModalContent} showModal={showModal} cardId={8} obj={cards[8]} animation={message} variant="darker" id="sell" highlightVariant="primary" />
                </Col>
                <Col lg={6} className={`${cardId == 9 ? 'z-index-998' : 'z-index-neutral'}`}>
                    <FlipCard toggleOverlay={toggleOverlay} toggleModal={toggleModal} setModalContent={setModalContent} showModal={showModal} cardId={9} obj={cards[9]} animation={creditCardChip} variant="muted" id="pos" />
                </Col>
                <Col lg={6} className={`${cardId == 10 ? 'z-index-998' : 'z-index-neutral'}`}>
                    <FlipCard toggleOverlay={toggleOverlay} toggleModal={toggleModal} setModalContent={setModalContent} showModal={showModal} cardId={10} obj={cards[10]} animation={chartCol} variant="darker" id="data" />
                </Col>
                <Col xs={12} className={`full-lottie ${cardId == 11 ? 'z-index-998' : 'z-index-neutral'}`}>
                    <FlipCard toggleOverlay={toggleOverlay} toggleModal={toggleModal} setModalContent={setModalContent} showModal={showModal} cardId={11} textAlign="center" obj={cards[11]} animation={noFees} mobileAnimation={noFeesMobile} variant="black" id="fees" />
                </Col>
            </Row>
        </section >
    );
}
