import React from 'react';

import Button from 'react-bootstrap/Button'

export default function FilterBtn({ handleShow }) {

    return (
        <Button
            className="btn--filter"
            variant="outline-light"
            onClick={handleShow}>
            Filter
        </Button>
    );
}
