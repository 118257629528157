import React from 'react';

import Alert from 'react-bootstrap/Alert';

export default function Error({ type, val }) {
    const getErrorType = (type) => {
        switch (type) {
            case 'invalidPhone':
                return 'Phone number is not valid. Please try again';

            case 'phone':
                return 'Phone number is not valid or already exists. Please try again';

            case 'invalidPassword':
                return 'Your password has to be at least 6 characters. Please try again';

            case 'email':
                return 'Email does not match records. Please try again';

            case 'invalidEmail':
                return 'Please enter a valid email address';
            // Disabled to test if sendgrid updates work
            // return val?.includes('icloud.com') ? 'We don\'t support icloud emails. Please enter another email address' : 'Please enter a valid email address';

            case 'code':
                return 'Code entered is incorrect. Please try again';

            case 'transfer':
                return 'You can\'t transfer tickets to yourself. Choose a different email address and try again'

            case 'dob':
                return 'Date of birth is not a valid date. Please try again'

            default:
                return 'We\'re experiencing technical difficulties. Please try again later.';
        }
    };
    return (
        <Alert variant='danger' className="gap-3 mt-3">
            <div className="body">
                <p>{getErrorType(type, val)}</p>
            </div>
        </Alert>
    );
}