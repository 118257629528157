import React from 'react';

import { LoadingContainer } from '../LoadingContainer';

import './pageLoadingContainer.scss';

// page loading
export default function PageLoadingContainer({ text = "Loading...", subtext, icon }) {

    return (
        <div id="page-loading-container">
            <LoadingContainer>
                <div className="loading-text-container">
                    <h1 className={`loading-text loading-text--${icon}`}>{text}</h1>
                </div>
                {subtext && (
                    <p className='loading-text-caption'>{subtext}</p>
                )}
            </LoadingContainer>
        </div>
    );
}
