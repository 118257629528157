import React, { Fragment } from "react";
import { QRCodeSVG } from 'qrcode.react';
import moment from "moment";

import { formatShortDate, getStartDateFormatter, formatShortAddress } from '../../utilities/helpers';

import Card from 'react-bootstrap/Card';
import Badge from 'react-bootstrap/Badge';
import Button from 'react-bootstrap/Button';
import Image from 'react-bootstrap/Image'
import Stack from 'react-bootstrap/Stack';

import QRCode from '../../assets/noqr.png';
import ApplePassButton from "./ApplePassButton/ApplePassButton";
import { GooglePassButton } from "./GooglePassButton";

export default function QRTicketCard({ pass, ticket, event, order, handleClick }) {

    const obj = ticket || pass;
    const eventObj = order?.event || event;

    const eventStart = moment(eventObj.start);
    const today = moment();
    const showQR = today.isSame(eventStart, 'day');

    return (
        <Fragment>
            <Card body className="ticket-card">
                {showQR &&
                    <QRCodeSVG
                        width="217"
                        height="217"
                        className="mb-3 p-0"
                        value={obj.checkInCode}
                    />
                }

                {!showQR &&
                    <Image
                        src={QRCode}
                        className="mb-3"
                    />
                }

                <div className="details d-flex-column">
                    <Card.Title as="h5">{eventObj?.name}</Card.Title>
                    <p className="event-details">
                        {formatShortDate(moment(eventObj?.start), getStartDateFormatter(eventObj))}<span className="venue">{eventObj?.venue.name}</span><span className="loc">
                            {formatShortAddress(eventObj?.venue)}
                        </span>
                    </p>
                    <>
                        <Badge bg="light" className="mt-2 text-dark badge-lg">
                            {obj?.type || obj?.name}
                        </Badge>
                        <Stack direction="vertical" className="mt-3">
                            <ApplePassButton event={eventObj} obj={obj} />
                            <GooglePassButton event={eventObj} obj={obj} />
                            <Button variant='outline-light' className="flex-grow-1 ml-0" size="xs" onClick={() => handleClick('details')}>Details</Button>
                        </Stack>
                    </>
                </div>
            </Card>
        </Fragment>
    )
}