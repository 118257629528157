import React from 'react';

import { Disclaimer, Footer } from '../../components'

export default function DisclaimerPage() {
	return (
			<>
			    <Disclaimer />
                <Footer />
            </>
	);
}
