import React from 'react';

export default function Disclaimer() {
    return (
        <div className="spacer docs-container docs">
            <span className="time-caption docs-date">Last updated: May 25, 2022</span>
            <h1 className="docs-heading">Disclaimer</h1>
            <div className="docs-header">
                <h2 className="docs-header-title">Interpretation and Definitions</h2>
            </div>

            <h3 className="normal">Interpretation</h3>
            <p>
                The words of which the initial letter is capitalized have meanings defined under the following
                conditions. The following definitions shall have the same meaning regardless of whether they appear in
                singular or in plural.
            </p>
            <section>
                <h3 className="normal mt-4">Definitions</h3>
                <p>For the purposes of this Disclaimer:</p>
                <ul>
                    <li>
                        <dl>
                            <dt>Company </dt>
                            <dd>
                                (referred to as either "the Company", "We", "Us" or "Our" in this Agreement) refers to
                                Blocktickets LLC, 320 W MAIN ST LEWISVILLE, TX 75057.
                            </dd>
                        </dl>
                    </li>
                    <li>
                        <dl>
                            <dt>Cookies </dt>
                            <dd>
                                are small files that are placed on Your computer, mobile device or any other device by a
                                website, containing the details of Your browsing history on that website among its many
                                uses.
                            </dd>
                        </dl>
                    </li>
                    <li>
                        <dl>
                            <dt>Website </dt>
                            <dd>
                                refers to Blocktickets, accessible from{' '}
                                <a target="_blank" href="/">
                                    www.blocktickets.xyz
                                </a>
                            </dd>
                        </dl>
                    </li>
                    <li>
                        <dl>
                            <dt>You </dt>
                            <dd>
                                means the individual accessing or using the Service, or the company, or other legal
                                entity on behalf of which such individual is accessing or using the Service, as
                                applicable. Under GDPR (General Data Protection Regulation), You can be referred to as
                                the Data Subject or as the User as you are the individual using the Service.
                            </dd>
                        </dl>
                    </li>
                </ul>
            </section>
            <section>
                <h2 className="docs-section-heading">Disclaimer</h2>
                <p>The information contained on the Service is for general information purposes only.</p>
                <p>The Company assumes no responsibility for errors or omissions in the contents of the Service.</p>
                <p>
                    In no event shall the Company be liable for any special, direct, indirect, consequential, or
                    incidental damages or any damages whatsoever, whether in an action of contract, negligence or other
                    tort, arising out of or in connection with the use of the Service or the contents of the Service.
                    The Company reserves the right to make additions, deletions, or modifications to the contents on the
                    Service at any time without prior notice.
                </p>
                <p>The Company does not warrant that the Service is free of viruses or other harmful components.</p>
            </section>
            <section>
                <h2 className="docs-section-heading">External Links Disclaimer</h2>
                <p>
                    The Service may contain links to external websites that are not provided or maintained by or in any
                    way affiliated with the Company.
                </p>
                <p>
                    Please note that the Company does not guarantee the accuracy, relevance, timeliness, or completeness
                    of any information on these external websites.
                </p>
            </section>
            <section>
                <h2 className="docs-section-heading">Advertising Disclaimer</h2>
                <p>
                    Third party advertisements and links to third party websites may also appear while using the
                    Service. The Company does not make any representation as to the accuracy or suitability of any of
                    the information contained in those advertisements or websites and does not accept any responsibility
                    or liability for the conduct or content of those advertisements and websites and the offerings made
                    by the third-parties.
                </p>
                <p>
                    Third party advertisements and links to other websites where goods or services are advertised are
                    not endorsements or recommendations by the Company of the third party sites, goods or services. The
                    Company takes no responsibility for the content of the ads, promises made, or the
                    quality/reliability of the products or services offered in all advertisements.
                </p>
            </section>
            <section>
                <h2 className="docs-section-heading">Errors and Omissions Disclaimer</h2>
                <p>
                    The information given by the Service is for general guidance on matters of interest only. Even if
                    the Company takes every precaution to insure that the content of the Service is both current and
                    accurate, errors can occur. Plus, given the changing nature of laws, rules and regulations, there
                    may be delays, omissions or inaccuracies in the information contained on the Service. The Company is
                    not responsible for any errors or omissions, or for the results obtained from the use of this
                    information.
                </p>
            </section>
            <section>
                <h2 className="docs-section-heading">Fair Use Disclaimer</h2>
                <p>
                    The Company may use copyrighted material which has not always been specifically authorized by the
                    copyright owner. The Company is making such material available for criticism, comment, news
                    reporting, teaching, scholarship, or research.
                </p>
                <p>
                    The Company believes this constitutes a "fair use" of any such copyrighted material as provided for
                    in section 107 of the United States Copyright law.
                </p>
                <p>
                    If You wish to use copyrighted material from the Servic You must obtain permission from the
                    copyright owner.
                </p>
            </section>
            <section>
                <h2 className="docs-section-heading">Views Expressed Disclaimer</h2>
                <p>
                    The Service may contain views and opinions which are those of the authors and do not necessarily
                    reflect the official policy or position of any other author, agency, organization, employer or
                    company, including the Company.
                </p>
                <p>
                    Comments published by users are their sole responsibility and the users will take full
                    responsibility, liability and blame for any libel or litigation that results from something written
                    in or as a direct result of something written in a comment. The Company is not liable for any
                    comment published by users and reserve the right to delete any comment for any reason whatsoever.
                </p>
            </section>
            <section>
                <h2 className="docs-section-heading">No Responsibility Disclaimer</h2>
                <p>
                    The information on the Service is provided with the understanding that the Company is not herein
                    engaged in rendering legal, accounting, tax, or other professional advice and services. As such, it
                    should not be used as a substitute for consultation with professional accounting, tax, legal or
                    other competent advisers.
                </p>
                <p>
                    In no event shall the Company or its suppliers be liable for any special, incidental, indirect, or
                    consequential damages whatsoever arising out of or in connection with your access or use or
                    inability to access or use the Service.
                </p>
            </section>
            <section>
                <h2 className="docs-section-heading">"Use at Your Own Risk" Disclaimer</h2>
                <p>
                    All information in the Service is provided "as is", with no guarantee of completeness, accuracy,
                    timeliness or of the results obtained from the use of this information, and without warranty of any
                    kind, express or implied, including, but not limited to warranties of performance, merchantability
                    and fitness for a particular purpose.
                </p>
                <p>
                    The Company will not be liable to You or anyone else for any decision made or action taken in
                    reliance on the information given by the Service or for any consequential, special or similar
                    damages, even if advised of the possibility of such damages.
                </p>
            </section>
            <section>
                <h2 className="docs-section-heading">Contact Us</h2>
                <p>If you have any questions about this Disclaimer, You can contact us:</p>
                <ul>
                    <li>
                        By email:{' '}
                        <a href="mailto:help@blocktickets.xyz" target="_blank">
                            help@blocktickets.xyz
                        </a>
                    </li>
                </ul>
            </section>
        </div>
    );
}
