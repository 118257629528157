import React from 'react';
import * as moment from 'moment';

import { formatDateTime, formatShortAddress, timezones } from '../../../../utilities/helpers';

import Card from 'react-bootstrap/Card';
import Stack from 'react-bootstrap/Stack';

import placeholder from '../../../../assets/placeholder.png';

export default function OrderPreview({ event }) {

    let cart = sessionStorage.getItem('cart');

    if (cart) cart = JSON.parse(cart);

    let ticketCount;
    let ticketType;

    if (cart.listing) {
        ticketCount = cart.listing.tickets.length;
        ticketType = `Resale • ${cart.listing.tickets[0]?.name}`
    };
    if (cart.ticket) {
        ticketCount = cart?.ticketCount;
        ticketType = `${cart.ticket?.name}`
    }

    let timezone = timezones(event?.venue?.timezone)
    let convertedStart = moment(event?.start).utcOffset(timezone?.offset, false)

    return (
        <Card body className="order-card card--light">
            <Stack direction="horizontal" gap={3}>
                <img
                    src={event?.image?.url || placeholder}
                    alt={event?.name}
                    width="64"
                    height="64"
                    className="event-image"
                />
                <Stack className="details">
                    <h1 className="event-name">{event?.name}</h1>
                    <p>
                        {formatDateTime(moment(convertedStart), 'dateOnly')}{event?.display_start_time && (
                            <span className="time">{formatDateTime(moment(convertedStart), 'timeOnly')}</span>
                        )}
                    </p>
                    <p>
                        {event?.venue?.name}<span className="loc">
                            {formatShortAddress(event?.venue)}
                        </span>
                    </p>
                    <p className='tickets'>{ticketCount} {ticketCount > 1 ? 'Tickets' : 'Ticket'}<span className='ticket-type'>{ticketType}</span></p>
                </Stack>

            </Stack>
        </Card>
    );
}