import React from 'react';

export default function SuccessDisclaimer() {
    return (
        <>

            <small className="disclaimer mt-4">
                If you have any question please reach out to{' '}
                <a href="mailto:help@blocktickets.xyz" target="_blank">help@blocktickets.xyz</a>
            </small>
        </>
    );
}
