import React from 'react';
import { useParams } from 'react-router-dom';

import { GuestPassDetailsWrapper } from '../../components';

export default function GuestPassDetailsPage() {

    let { eventUUID } = useParams();

    return <GuestPassDetailsWrapper eventId={eventUUID} />
}