import React, { Fragment, useRef } from 'react'

export default function Seat({ selectSeat, unselectSeat, setShouldShow, showHTMLTooltip, seat }) {

    const seatRef = useRef(null);

    const seatClickRouter = () => {
        if (window.innerWidth <= 768) {
            showHTMLTooltip(seatRef.current, seat.id);
            return;
        };
        selectSeat(seat.id);
    }

    const seatMouseRouter = () => {
        if (window.innerWidth <= 768) {
            return;
        };
        showHTMLTooltip(seatRef.current, seat.id);
    }

    return (
        <Fragment>
            {seat.selected ? (
                <g className='rect-group'>
                    <rect x={seat.cx} y={seat.cy} width={seat.r * 2} height={seat.r * 2} fill="#45b26b" style={{ cursor: 'pointer' }}
                        onClick={() => unselectSeat(seat.id)} onMouseOut={() => setShouldShow(false)}></rect>
                    <path className='success' d="M86.67 0 33.33 53.33 13.33 33.33 0 46.67 33.33 80 100 13.33 86.67 0 Z"
                        transform={`translate(${seat.cx + 5},${seat.cy + 11.5}) scale(0.5)`}
                        style={{ pointerEvents: 'none' }}></path>
                </g>
            )
                :
                (
                    <rect ref={seatRef} className='seat' x={seat.cx} y={seat.cy} id="htmltooltip" width={seat.r * 2} height={seat.r * 2}
                        onMouseEnter={() => seatMouseRouter()}
                        onClick={() => seatClickRouter()}
                        onMouseOut={() => {
                            console.log("OUT");
                            setShouldShow(false);
                        }}></rect>
                )}
        </Fragment>
    )
}
