import React, { Fragment, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useStripe, useElements } from '@stripe/react-stripe-js';

import { createOrder } from '../../../../utilities/api';
import { formatCurrency } from '../../../../utilities/helpers';

import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Stack from 'react-bootstrap/Stack';
import Card from 'react-bootstrap/Card';

import { PopupCard } from '../../../PopupCard';
import { Disclaimer } from './Disclaimer';
import { Spinner } from '../../../LoadingContainer/Spinner';
import { Error } from '../../../Error';

import './totalCard.scss';

export default function TotalCard({ setStatus, addOns, setOrder, intentId, paymentDeclined }) {
    const [
        expanded,
        setExpanded
    ] = useState(false);

    const [
        isPurchasing,
        setIsPurchasing
    ] = useState(false);

    const [
        hasError,
        setHasError
    ] = useState(false);

    const [
        errorShow,
        setErrorShow
    ] = useState({
        error: false,
        msg: '',
        remaining: 0
    });

    const navigate = useNavigate();

    let cart = sessionStorage.getItem('cart');
    if (cart) cart = JSON.parse(cart);

    const stripe = useStripe();
    const elements = useElements();

    let ticketPrice;
    let ticketCount;
    let ticketFee;
    let facilityFee;
    let totalDue;
    let tax;
    let processingFee;
    let times;
    let showServiceFee = true;
    let showFacilityFee = true;

    if (cart.listing) {
        let listing = cart?.listing;
        ticketPrice = listing?.pricing?.ticketCost;
        ticketCount = listing?.quantity;
        ticketFee = listing?.pricing?.serviceFees;
        facilityFee = listing?.pricing?.ticketFacilityFee;
        processingFee = listing?.pricing?.paymentProcessingFee * listing?.quantity;
        totalDue = listing?.pricing?.listingTotalWithFees;
        tax = listing?.pricing?.taxPerTicket * listing?.quantity;
        times = ticketCount;
    }
    else if (cart.ticket) {
        let ticket = cart?.ticket;
        ticketPrice = ticket?.pricing?.ticketCost;
        ticketCount = cart.ticketCount;
        ticketFee = ticket?.pricing?.serviceFees;
        facilityFee = ticket?.pricing?.ticketFacilityFee;
        processingFee = (ticket?.pricing?.processingFee + ticket?.pricing?.chargeBackProtection + ticket?.pricing?.serviceFees) * ticketCount;
        totalDue = (ticket?.pricing?.totalDue) * ticketCount;
        tax = ticket?.pricing?.salesTax * ticketCount;
        times = ticketCount;
        showServiceFee = ticket?.pricing?.serviceFees > 0 ? true : false;
        showFacilityFee = ticket?.pricing?.ticketFacilityFee > 0 ? true : false;
    }

    const completePurchase = () => {
        setIsPurchasing(true);
        let data = {
            cart: cart,
            paymentIntentId: intentId
        };

        createOrder(data)
            .then((res) => {
                // Need better way to store order data
                sessionStorage.setItem('order', JSON.stringify(res.data));
                sendPayment();
            })
            .catch((err) => {
                console.error(err);
                setIsPurchasing(false);
                setHasError(true)
                setErrorShow({
                    error: true,
                    msg: err.response?.data?.error?.message,
                    remaining: err.response?.data?.error?.details?.ticketsRemaining
                })
            })
    }

    const sendPayment = async () => {
        if (!stripe || !elements) {
            // Stripe.js has not yet loaded.
            // Make sure to disable form submission until Stripe.js has loaded.
            return;
        }

        const { error } = await stripe.confirmPayment({
            //`Elements` instance that was used to create the Payment Element
            elements,
            confirmParams: {
                return_url: ''
            },
            redirect: 'if_required'
        });

        if (error) {
            // This point will only be reached if there is an immediate error when
            // confirming the payment. Show error to your customer (for example, payment
            // details incomplete)
            setIsPurchasing(false);
            paymentDeclined(error.message);
            console.warn(error.message);
        }
        else {
            // Your customer will be redirected to your `return_url`. For some payment
            // methods like iDEAL, your customer will be redirected to an intermediate
            // site first to authorize the payment, then redirected to the `return_url`.
            setStatus('successful');
        }
    };

    return (
        <>
            <PopupCard amount={totalDue} ticketCount={ticketCount} canExpand={true} expanded={expanded} setExpanded={setExpanded}>
                {expanded && (
                    <Card.Body className='scrollable-area'>
                        <ul>
                            <li className="list">
                                <p className="heading">Tickets</p>
                                <ul>
                                    <Stack direction="horizontal" as="li" className="split-row">
                                        <span>
                                            Tickets: {formatCurrency(ticketPrice)} x {ticketCount}
                                        </span>
                                        <span className="text-end">
                                            {formatCurrency(ticketPrice * ticketCount)}
                                        </span>
                                    </Stack>
                                </ul>
                            </li>
                            <li className="list">
                                <p className="heading">Fees</p>
                                <ul>
                                    {showFacilityFee &&
                                        <Stack direction="horizontal" as="li" className="split-row">
                                            <span>
                                                Facility Fee: {formatCurrency(facilityFee)} x {times}
                                            </span>
                                            <span className="text-end">
                                                {formatCurrency(facilityFee * times)}
                                            </span>
                                        </Stack>
                                    }
                                    <Stack direction="horizontal" as="li" className="split-row">
                                        <span>
                                            Processing Fee: {formatCurrency(processingFee)}
                                        </span>
                                        <span className="text-end">
                                            {formatCurrency(processingFee)}
                                        </span>
                                    </Stack>
                                </ul>
                            </li>

                            {addOns.length > 0 && (
                                <li className="list">
                                    <p className="heading">Add on</p>
                                    <ul>
                                        <Stack direction="horizontal" as="li" className="split-row">
                                            <span>Meet &amp; Greet</span>

                                            <span className="text-end">$45.00</span>
                                        </Stack>
                                        <Stack direction="horizontal" as="li" className="split-row">
                                            <span>Merch</span>

                                            <span className="text-end">$45.00</span>
                                        </Stack>
                                        <Stack direction="horizontal" as="li" className="split-row">
                                            <span>Parking Pass</span>

                                            <span className="text-end">$12.00</span>
                                        </Stack>
                                    </ul>
                                </li>
                            )}

                            <li className="split-row list">
                                <span className="heading m-0">Tax</span>
                                <span className="text-end">{formatCurrency(tax)}</span>
                            </li>
                        </ul>
                        {/* <div className="mt-4">
                            <Disclaimer />
                        </div> */}
                    </Card.Body >
                )}
                <Card.Footer>
                    <div className={`mt-3 ${expanded ? 'd-block' : 'd-none'}`}>
                        <Disclaimer />
                    </div>
                    <div id="payment-request-button">
                        {/* Need to rethink how payment element and totalCard manage state */}
                        {/* after payment has been entered  */}
                        <Button
                            id="payment-btn"
                            variant="primary"
                            size="lg"
                            disabled={isPurchasing}
                            className="icon-button w-100 d-none"
                            onClick={completePurchase}>
                            {isPurchasing ? (
                                <Fragment>
                                    <Spinner />
                                    <span>Purchasing...</span>
                                </Fragment>
                            ) : (
                                <span>Complete Purchase</span>
                            )}
                        </Button>
                    </div>

                    <div id="payment-request-button">
                        {/* Need to rethink how payment element and totalCard manage state */}
                        {/* before payment has been entered */}
                        <Button
                            id="payment-btn-disabled"
                            disabled
                            variant="primary"
                            size="lg"
                            className="icon-button w-100">
                            <span>Complete Purchase</span>
                        </Button>
                    </div>

                    {hasError && (
                        <Error />
                    )}
                </Card.Footer>
            </PopupCard>
            <Modal
                show={errorShow.error}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header>
                    <Modal.Title id="contained-modal-title-vcenter">
                        Limited Ticket Availability
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>
                        Please adjust your ticket selection <strong>{errorShow.remaining}</strong> tickets remaining.
                    </p>
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={() => navigate(-1)}>Go Back</Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}
