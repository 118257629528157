import React from 'react';

export default function CookiesPolicy() {
    return (
        <div className="spacer docs-container docs">
            <span className="time-caption docs-date">Last updated: May 25, 2022</span>
            <h1 className="docs-heading">Cookies Policy</h1>
            <p className="doc-desc">
                This Cookies Policy explains what Cookies are and how We use them. You should read this policy so You
                can understand what type of cookies We use, or the information We collect using Cookies and how that
                information is used.
            </p>
            <p className="doc-desc">
                Cookies do not typically contain any information that personally identifies a user, but personal
                information that we store about You may be linked to the information stored in and obtained from
                Cookies. For further information on how We use, store and keep your personal data secure, see our{' '}
                <a href="/privacy-policy" target="_blank">
                    Privacy Policy
                </a>.
            </p>
            <p className="doc-desc">
                We do not store sensitive personal information, such as mailing addresses, account passwords, etc. in
                the Cookies We use.
            </p>

            <div className="docs-header">
                <h2 className="docs-header-title">Interpretation and Definitions</h2>
            </div>

            <h3 className="normal">Interpretation</h3>
            <p>
                The words of which the initial letter is capitalized have meanings defined under the following
                conditions. The following definitions shall have the same meaning regardless of whether they appear in
                singular or in plural.
            </p>
            <section>
                <h3 className="normal mt-4">Definitions</h3>
                <p>For the purposes of this Cookies Policy:</p>
                <ul>
                    <li>
                        <dl>
                            <dt>Company </dt>
                            <dd>
                                (referred to as either "the Company", "We", "Us" or "Our" in this Agreement) refers to
                                Blocktickets LLC, 320 W MAIN ST LEWISVILLE, TX 75057.
                            </dd>
                        </dl>
                    </li>
                    <li>
                        <dl>
                            <dt>Cookies </dt>
                            <dd>
                                are small files that are placed on Your computer, mobile device or any other device by a
                                website, containing the details of Your browsing history on that website among its many
                                uses.
                            </dd>
                        </dl>
                    </li>
                    <li>
                        <dl>
                            <dt>Website </dt>
                            <dd>
                                refers to Blocktickets, accessible from{' '}
                                <a target="_blank" href="/">
                                    www.blocktickets.xyz
                                </a>
                            </dd>
                        </dl>
                    </li>
                    <li>
                        <dl>
                            <dt>You </dt>
                            <dd>
                                means the individual accessing or using the Service, or the company, or other legal
                                entity on behalf of which such individual is accessing or using the Service, as
                                applicable. Under GDPR (General Data Protection Regulation), You can be referred to as
                                the Data Subject or as the User as you are the individual using the Service.
                            </dd>
                        </dl>
                    </li>
                </ul>
            </section>
            <section>
                <h2 className="docs-section-heading">The use of the Cookies</h2>
                <h3 className="normal">Type of Cookies We Use</h3>
                <p>
                    Cookies can be "Persistent" or "Session" Cookies. Persistent Cookies remain on your personal
                    computer or mobile device when You go offline, while Session Cookies are deleted as soon as You
                    close your web browser.
                </p>
                <p>We use both session and persistent Cookies for the purposes set out below:</p>
                <ul className="unstyled-list float">
                    <li>
                        <dl>
                            <dt>Necessary / Essential Cookies</dt>
                            <dt>Type:</dt>
                            <dd>Session Cookies</dd>
                            <dt>Administered by:</dt>
                            <dd>Us</dd>
                            <dt>Purpose:</dt>
                            <dd>
                                These Cookies are essential to provide You with services available through the Website
                                and to enable You to use some of its features. They help to authenticate users and
                                prevent fraudulent use of user accounts. Without these Cookies, the services that You
                                have asked for cannot be provided, and We only use these Cookies to provide You with
                                those services.
                            </dd>
                        </dl>
                    </li>
                    <li>
                        <dl>
                            <dt>Cookes Policy / Notice Acceptance Cookies</dt>
                            <dt>Type: </dt>
                            <dd>Persistent Cookies</dd>
                            <dt>Administered by: </dt>
                            <dd>Us</dd>
                            <dt>Purpose:</dt>
                            <dd>These Cookies identify if users have accepted the use of cookies on the Website.</dd>
                        </dl>
                    </li>
                    <li>
                        <dl>
                            <dt>Functionality Cookies</dt>
                            <dt>Type: </dt>
                            <dd>Persistent Cookies</dd>
                            <dt>Administered by: </dt>
                            <dd>Us</dd>
                            <dt>Purpose:</dt>
                            <dd>
                                These Cookies allow us to remember choices You make when You use the Website, such as
                                remembering your login details or language preference. The purpose of these Cookies is
                                to provide You with a more personal experience and to avoid You having to re-enter your
                                preferences every time You use the Website.
                            </dd>
                        </dl>
                    </li>
                    <li>
                        <dl>
                            <dt>Tracking and Performance Cookies</dt>
                            <dt>Type: </dt>
                            <dd>Persistent Cookies</dd>
                            <dt>Administered by: </dt>
                            <dd>Third-Parties</dd>
                            <dt>Purpose:</dt>
                            <dd>
                                These Cookies are used to track information about traffic to the Website and how users
                                use the Website. The information gathered via these Cookies may directly or indirectly
                                identify you as an individual visitor. This is because the information collected is
                                typically linked to a pseudonymous identifier associated with the device you use to
                                access the Website. We may also use these Cookies to test new pages, features or new
                                functionality of the Website to see how our users react to them.
                            </dd>
                        </dl>
                    </li>
                    <li>
                        <dl>
                            <dt>Targeting and Advertising Cookies</dt>
                            <dt>Type: </dt>
                            <dd>Persistent Cookies</dd>
                            <dt>Administered by: </dt>
                            <dd>Third-Parties</dd>
                            <dt>Purpose:</dt>
                            <dd>
                                These Cookies track your browsing habits to enable Us to show advertising which is more
                                likely to be of interest to You. These Cookies use information about your browsing
                                history to group You with other users who have similar interests. Based on that
                                information, and with Our permission, third party advertisers can place Cookies to
                                enable them to show adverts which We think will be relevant to your interests while You
                                are on third party websites.
                            </dd>
                        </dl>
                    </li>
                </ul>

                <h3 className="normal mt-4">Your Choices Regarding Cookies</h3>
                <p>
                    If You prefer to avoid the use of Cookies on the Website, first You must disable the use of Cookies
                    in your browser and then delete the Cookies saved in your browser associated with this website. You
                    may use this option for preventing the use of Cookies at any time. If You do not accept Our Cookies,
                    You may experience some inconvenience in your use of the Website and some features may not function
                    properly. If You'd like to delete Cookies or instruct your web browser to delete or refuse Cookies,
                    please visit the help pages of your web browser.
                </p>
                <ul>
                    <li>
                        For the Chrome web browser, please visit this page from Google:{' '}
                        <a href="https://support.google.com/accounts/answer/32050" target="_blank">
                            https://support.google.com/accounts/answer/32050
                        </a>
                    </li>
                    <li>
                        For the Internet Explorer web browser, please visit this page from Microsoft:{' '}
                        <a href="http://support.microsoft.com/kb/278835" target="_blank">
                            http://support.microsoft.com/kb/278835
                        </a>
                    </li>
                    <li>
                        For the Firefox web browser, please visit this page from Mozilla:{' '}
                        <a
                            href="https://support.mozilla.org/en-US/kb/delete-cookies-remove-info-websites-stored"
                            target="_blank">
                            https://support.mozilla.org/en-US/kb/delete-cookies-remove-info-websites-stored
                        </a>
                    </li>
                    <li>
                        For the Safari web browser, please visit this page from Apple:{' '}
                        <a
                            href="https://support.apple.com/guide/safari/manage-cookies-and-website-data-sfri11471/mac"
                            target="_blank">
                            https://support.apple.com/guide/safari/manage-cookies-and-website-data-sfri11471/mac
                        </a>
                    </li>
                </ul>
                <p>For any other web browser, please visit your web browser's official web pages.</p>
            </section>
            <section>
                <h2 className="docs-section-heading">More information about Cookies</h2>
                <p>
                    You can learn more about cookies here:{' '}
                    <a href="https://www.termsfeed.com/blog/cookies/" target="_blank">
                        All About Cookies by TermsFeed
                    </a>.
                </p>
            </section>
            <section>
                <h2 className="docs-section-heading">Contact Us</h2>
                <p>If you have any questions about this Cookies Policy, You can contact us:</p>
                <ul>
                    <li>
                        By email:{' '}
                        <a href="mailto:help@blocktickets.xyz" target="_blank">
                            help@blocktickets.xyz
                        </a>
                    </li>
                </ul>
            </section>
        </div>
    );
}
