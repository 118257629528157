import { downloadGooglePass } from "../../../utilities/api"
import addToGoogleWalletSVG from '../../../assets/icons/add-to-google-wallet.svg'
import Button from 'react-bootstrap/Button';


export default function GooglePassButton({ event, obj }) {
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;
    const isAndroid = /android/i.test(userAgent);
    const downloadGoogleWalletPass = async () => {
        const payload = {
            event: event,
            ticket: obj,
        }
        const response = await downloadGooglePass(payload);

        // Iw all went well, download the pkpass or pkpasses file/'s
        if (response.status === 200) {
            console.log("Google response: ", response);
            window.open(response.data, '_blank');
        } else {
          console.error('Failed to download the pass.');
        }
    }

    if (!isAndroid) return null;

    return (
        <Button onClick={downloadGoogleWalletPass} variant="info" id="google-wallet-btn" aria-label="Add to Google Wallet" className="mt-0">
            <img src={addToGoogleWalletSVG} alt="add to google wallet" />
        </Button>
    )
}