import React, { useEffect, useContext } from 'react';

import TicketContext from '../../../../../context/Ticket/Ticket';

import { formatCurrency } from '../../../../../utilities/helpers';

import Button from 'react-bootstrap/Button';
import Stack from 'react-bootstrap/Stack';

import { TicketPurchasePopupCard } from '../TicketPurchasePopupCard';
import { BackButton } from '../../../../BackButton';

import badge from '../../../../../assets/map-general_admission-badge.svg'

import './ticketConfirmation.scss';

export default function TicketConfirmation({ handleGoBack, eventType, ticket, listing, setTicketCount, ticketCount, code }) {
    let ticketName;
    let maxQuantity;
    let ticketType;
    let hasDesciption;
    let ticketPrice;
    let ticketPriceWithFeesAndTax;
    let totalDue;

    const ticketContext = useContext(TicketContext);

    if (listing) {
        ticketPrice = listing?.pricing?.ticketCost;
        ticketName = listing?.pricing?.ticketName;
        maxQuantity = listing?.quantity;
        ticketType = listing?.pricing?.ticketType;
        ticketPriceWithFeesAndTax = listing?.pricing?.ticketCostWithFeesAndTax;
        totalDue = ticketPriceWithFeesAndTax * ticketCount
    } else if (ticket) {
        ticketPrice = ticket?.pricing?.ticketCost;
        ticketName = ticket?.pricing?.ticketName;
        maxQuantity = ticket?.maximum_quantity;
        ticketType = ticket?.pricing?.ticketType;
        ticketPriceWithFeesAndTax = ticket?.pricing?.totalDue;
        totalDue = ticketPriceWithFeesAndTax * ticketCount;
        hasDesciption = ticket?.description.split('').length > 0 ? true : false;
    }

    useEffect(
        () => {
            let data = {
                ticket,
                ticketCount,
                listing,
                promoCode: code
            };
            sessionStorage.setItem('cart', JSON.stringify(data));
        },
        [
            ticket, listing, ticketCount
        ]
    );
    return (
        <>
            <header>
                <div className="tablet-desktop-only">
                    <BackButton handleGoBack={handleGoBack} marginBottom="2" />
                </div>
                <div className="ticket-details d-flex flex-column">
                    <div className="fw-bold d-flex justify-content-between align-items-center pb-4 gap-3">
                        <h1 className="normal--uppercase mb-0">{ticketName}</h1>
                        <img
                            src={badge}
                            alt=""
                            width="87"
                            height="44"
                        />
                    </div>
                </div>
            </header>
            <div className="full-height-container">
                <div className="scrollable-area">
                    <div id="ticket-info">
                        <Stack direction="horizontal" className="justify-content-between">
                            <Stack className="align-items-start" gap={1}>
                                <span className="ticket-limit">Event Ticket limit: {maxQuantity}</span>
                                <span className="type">
                                    {ticketType}
                                </span>
                                <span className="total">
                                    {formatCurrency(ticketPriceWithFeesAndTax)} ea</span>
                                <span className="tax-fees">Incl. Taxes & Fees</span>
                            </Stack>
                            <Stack direction="horizontal" className="fw-bolder" id="ticket-counter">
                                <Button
                                    title="decrease quantity of tickets"
                                    className="btn--icon"
                                    variant="outline-light"
                                    onClick={() => setTicketCount(ticketCount - 1)}
                                    disabled={listing ? true : ticket ? ticketCount === ticket.minimum_quantity : false}
                                    aria-disabled={ticket ? ticketCount === ticket.minimum_quantity : 'true'}>
                                    <svg xmlns="http://www.w3.org/2000/svg" role="img" width="20" height="2" viewBox="0 0 20 2" fill="none">
                                        <path fillRule="evenodd" clipRule="evenodd" d="M0.666992 1C0.666992 0.447715 1.11471 0 1.66699 0H18.3337C18.8859 0 19.3337 0.447715 19.3337 1C19.3337 1.55228 18.8859 2 18.3337 2H1.66699C1.11471 2 0.666992 1.55228 0.666992 1Z" />
                                    </svg>
                                </Button>
                                <div id="ticket-count">
                                    <span>
                                        {listing ? listing.tickets.length : ticketCount}</span>
                                </div>
                                <Button
                                    title="increase quantity of tickets"
                                    className="btn--icon ms-0"
                                    variant="outline-light"
                                    onClick={() => setTicketCount(ticketCount + 1)}
                                    disabled={listing ? true : (ticketCount >= (ticket.resale ? 1 : ticket.maximum_quantity) || ticketCount >= ticketContext.generalAdmissionCount)}
                                    aria-disabled={listing ? true : ticket ? ticketCount >= ticket.maximum_quantity : true}>
                                    <svg xmlns="http://www.w3.org/2000/svg" role="img" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                        <path fillRule="evenodd" clipRule="evenodd" d="M11.0003 1.66699C11.0003 1.11471 10.5526 0.666992 10.0003 0.666992C9.44804 0.666992 9.00033 1.11471 9.00033 1.66699V9.00033H1.66699C1.11471 9.00033 0.666992 9.44804 0.666992 10.0003C0.666992 10.5526 1.11471 11.0003 1.66699 11.0003H9.00033V18.3337C9.00033 18.8859 9.44804 19.3337 10.0003 19.3337C10.5526 19.3337 11.0003 18.8859 11.0003 18.3337V11.0003H18.3337C18.8859 11.0003 19.3337 10.5526 19.3337 10.0003C19.3337 9.44804 18.8859 9.00033 18.3337 9.00033H11.0003V1.66699Z" />
                                    </svg>
                                </Button>
                            </Stack>
                        </Stack>
                    </div>
                    {hasDesciption && (
                        <div>
                            <span className='fw-medium small'>Ticket description</span>
                            <pre className='small text-muted m-0 py-3'>{ticket?.description}</pre>
                        </div>
                    )}
                </div>
                <TicketPurchasePopupCard amount={totalDue} ticketCount={ticketCount} canExpand={false} />
            </div>
        </>
    );
}
