import React from 'react';

import { PurchasePolicy, Footer } from '../../components'

export default function PurchasePolicyPage() {
	return (
			<>
			    <PurchasePolicy />
                <Footer />
            </>
	);
}
