import React from 'react';

import Stack from 'react-bootstrap/Stack';
import Button from 'react-bootstrap/Button';

import './mySeats.scss';

export default function MySeats({selectedSeats}) {
    return (
        <>
            {selectedSeats.map((seat, index) => {
                return (
                    <li className="ticket seat-card mt-3" key={index}>
                        <Stack direction="horizontal" className="seat-info justify-content-between">
                            <div className="seat-info-block">
                                <span className="seat-info-block-label">Sec</span>
                                <span className="seat-info-block-value">101</span>
                            </div>
                            <div className="seat-info-block">
                                <span className="seat-info-block-label">Row</span>
                                <span className="seat-info-block-value">5</span>
                            </div>
                            <div className="seat-info-block">
                                <span className="seat-info-block-label">Seat</span>
                                <span className="seat-info-block-value">9</span>
                            </div>
                        </Stack>
                        <Stack direction="horizontal" className="ticket-info justify-content-between">
                            <div>
                                <span className="type">General Admission</span>
                            </div>
                            <div className='text-end'>
                                <div>
                                    <span className="total">$30.00</span>
                                </div>
                                <div>
                                    <span className="tax-fees">
                                        Incl. Taxes and Fees
                                    </span>
                                </div>
                            </div>
                        </Stack>
                        <Button variant="outline-light" className="btn--icon" />
                    </li>
                )
            })}
            {/* <li className="ticket seat-card mt-3">
                <Stack direction="horizontal" className="seat-info justify-content-between">
                    <div className="seat-info-block">
                        <span className="seat-info-block-label">Sec</span>
                        <span className="seat-info-block-value">101</span>
                    </div>
                    <div className="seat-info-block">
                        <span className="seat-info-block-label">Row</span>
                        <span className="seat-info-block-value">5</span>
                    </div>
                    <div className="seat-info-block">
                        <span className="seat-info-block-label">Seat</span>
                        <span className="seat-info-block-value">9</span>
                    </div>
                </Stack>
                <Stack direction="horizontal" className="ticket-info ticket-info-row">
                    <div>
                        <span className="type">General Admission</span>
                    </div>
                    <div className='ticket-price'>
                        <div>
                            <span className="total">$30.00</span>
                        </div>
                        <div>
                            <span className="tax-fees">
                                Incl. Taxes and Fees
                            </span>
                        </div>
                    </div>
                </Stack>
                <Button variant="outline-light" className="btn--icon" />
            </li>
            <li className="ticket seat-card">
                <Stack direction="horizontal" className="seat-info justify-content-between">
                    <div className="seat-info-block">
                        <span className="seat-info-block-label">Sec</span>
                        <span className="seat-info-block-value">101</span>
                    </div>
                    <div className="seat-info-block">
                        <span className="seat-info-block-label">Row</span>
                        <span className="seat-info-block-value">5</span>
                    </div>
                    <div className="seat-info-block">
                        <span className="seat-info-block-label">Seat</span>
                        <span className="seat-info-block-value">9</span>
                    </div>
                </Stack>
                <Stack direction="horizontal" className="ticket-info ticket-info-row">
                    <div>
                        <span className="type">General Admission</span>
                    </div>
                    <div className='ticket-price'>
                        <div>
                            <span className="total">$30.00</span>
                        </div>
                        <div>
                            <span className="tax-fees">
                                Incl. Taxes and Fees
                            </span>
                        </div>
                    </div>
                </Stack>
                <Button variant="outline-light" className="btn--icon" />
            </li>
            <li className="ticket seat-card">
                <Stack direction="horizontal" className="seat-info justify-content-between">
                    <div className="seat-info-block">
                        <span className="seat-info-block-label">Sec</span>
                        <span className="seat-info-block-value">101</span>
                    </div>
                    <div className="seat-info-block">
                        <span className="seat-info-block-label">Row</span>
                        <span className="seat-info-block-value">5</span>
                    </div>
                    <div className="seat-info-block">
                        <span className="seat-info-block-label">Seat</span>
                        <span className="seat-info-block-value">9</span>
                    </div>
                </Stack>
                <Stack direction="horizontal" className="ticket-info ticket-info-row">
                    <div>
                        <span className="type">General Admission</span>
                    </div>
                    <div className='ticket-price'>
                        <div>
                            <span className="total">$30.00</span>
                        </div>
                        <div>
                            <span className="tax-fees">
                                Incl. Taxes and Fees
                            </span>
                        </div>
                    </div>
                </Stack>
                <Button variant="outline-light" className="btn--icon" />
            </li>
            <li className="ticket seat-card">
                <Stack direction="horizontal" className="seat-info justify-content-between">
                    <div className="seat-info-block">
                        <span className="seat-info-block-label">Sec</span>
                        <span className="seat-info-block-value">101</span>
                    </div>
                    <div className="seat-info-block">
                        <span className="seat-info-block-label">Row</span>
                        <span className="seat-info-block-value">5</span>
                    </div>
                    <div className="seat-info-block">
                        <span className="seat-info-block-label">Seat</span>
                        <span className="seat-info-block-value">9</span>
                    </div>
                </Stack>
                <Stack direction="horizontal" className="ticket-info ticket-info-row">
                    <div>
                        <span className="type">General Admission</span>
                    </div>
                    <div className='ticket-price'>
                        <div>
                            <span className="total">$30.00</span>
                        </div>
                        <div>
                            <span className="tax-fees">
                                Incl. Taxes and Fees
                            </span>
                        </div>
                    </div>
                </Stack>
                <Button variant="outline-light" className="btn--icon" />
            </li>
            <li className="ticket seat-card">
                <Stack direction="horizontal" className="seat-info justify-content-between">
                    <div className="seat-info-block">
                        <span className="seat-info-block-label">Sec</span>
                        <span className="seat-info-block-value">101</span>
                    </div>
                    <div className="seat-info-block">
                        <span className="seat-info-block-label">Row</span>
                        <span className="seat-info-block-value">5</span>
                    </div>
                    <div className="seat-info-block">
                        <span className="seat-info-block-label">Seat</span>
                        <span className="seat-info-block-value">9</span>
                    </div>
                </Stack>
                <Stack direction="horizontal" className="ticket-info ticket-info-row">
                    <div>
                        <span className="type">General Admission</span>
                    </div>
                    <div className='ticket-price'>
                        <div>
                            <span className="total">$30.00</span>
                        </div>
                        <div>
                            <span className="tax-fees">
                                Incl. Taxes and Fees
                            </span>
                        </div>
                    </div>
                </Stack>
                <Button variant="outline-light" className="btn--icon" />
            </li>
            <li className="ticket seat-card">
                <Stack direction="horizontal" className="seat-info justify-content-between">
                    <div className="seat-info-block">
                        <span className="seat-info-block-label">Sec</span>
                        <span className="seat-info-block-value">101</span>
                    </div>
                    <div className="seat-info-block">
                        <span className="seat-info-block-label">Row</span>
                        <span className="seat-info-block-value">5</span>
                    </div>
                    <div className="seat-info-block">
                        <span className="seat-info-block-label">Seat</span>
                        <span className="seat-info-block-value">9</span>
                    </div>
                </Stack>
                <Stack direction="horizontal" className="ticket-info ticket-info-row">
                    <div>
                        <span className="type">General Admission</span>
                    </div>
                    <div className='ticket-price'>
                        <div>
                            <span className="total">$30.00</span>
                        </div>
                        <div>
                            <span className="tax-fees">
                                Incl. Taxes and Fees
                            </span>
                        </div>
                    </div>
                </Stack>
                <Button variant="outline-light" className="btn--icon" />
            </li>
            <li className="ticket seat-card">
                <Stack direction="horizontal" className="seat-info justify-content-between">
                    <div className="seat-info-block">
                        <span className="seat-info-block-label">Sec</span>
                        <span className="seat-info-block-value">101</span>
                    </div>
                    <div className="seat-info-block">
                        <span className="seat-info-block-label">Row</span>
                        <span className="seat-info-block-value">5</span>
                    </div>
                    <div className="seat-info-block">
                        <span className="seat-info-block-label">Seat</span>
                        <span className="seat-info-block-value">9</span>
                    </div>
                </Stack>
                <Stack direction="horizontal" className="ticket-info ticket-info-row">
                    <div>
                        <span className="type">General Admission</span>
                    </div>
                    <div className='ticket-price'>
                        <div>
                            <span className="total">$30.00</span>
                        </div>
                        <div>
                            <span className="tax-fees">
                                Incl. Taxes and Fees
                            </span>
                        </div>
                    </div>
                </Stack>
                <Button variant="outline-light" className="btn--icon" />
            </li> */}
        </>
    );
}
