import React, { useState, useEffect, useLayoutEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';

import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';

import LoadingContext from '../../context/Loading/Loading';

import { getEvent, createOrder, getPaymentIntent } from '../../utilities/api';
import { toggleNavContent, fullHeightContainer, removeFullHeightContainer, toggleElement } from '../../utilities/helpers';

import Row from 'react-bootstrap/Row';
import Modal from 'react-bootstrap/Modal';
import Stack from 'react-bootstrap/Stack';
import Button from 'react-bootstrap/Button';

import { PageLoadingContainer } from '../PageLoadingContainer';
import { Checkout } from './Checkout';
import { PaymentConfirmation } from './PaymentConfirmation';

import './checkoutWrapper.scss';

export default function CheckoutWrapper() {

    const { isLoading, showLoading, hideLoading } = useContext(LoadingContext)

    const navigate = useNavigate();

    let show = true;

    // Make sure to call `loadStripe` outside of a component’s render to avoid
    // recreating the `Stripe` object on every render.
    const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY);

    const [
        clientSecret,
        setClientSecret
    ] = useState('');

    const [
        status,
        setStatus
    ] = useState('checkout');

    const [
        intentId,
        setIntentId
    ] = useState('');

    const [event, setEvent] = useState()

    const [
        order,
        setOrder
    ] = useState();

    const [
        errorShow,
        setErrorShow
    ] = useState({
        error: false,
        msg: '',
        remaining: 0
    });

    useLayoutEffect(() => {
        document.getElementById('logo-link').style.pointerEvents = 'none';
        const btns = document.querySelector('.desktop-btns');
        const nav = document.querySelector('.navbar-nav');
        const timer = document.getElementById('timer-container');

        toggleElement(timer, show);
        toggleNavContent(!show, btns, nav);

        const el = document.querySelector('#main-container');

        fullHeightContainer(el);

        return () => {
            toggleElement(timer, !show);
            toggleNavContent(show, btns, nav);
            removeFullHeightContainer(el);
            if (document.getElementById('logo-link')) document.getElementById('logo-link').style.pointerEvents = 'auto';
        };
    }, []);

    useEffect(() => {
        showLoading()

        let cart = sessionStorage.getItem('cart');
        if (cart) cart = JSON.parse(cart);

        let eventId;

        if (cart.listing) {
            eventId = cart.listing.event.uuid;
        }

        if (cart.ticket) {
            eventId = cart.ticket.eventId;
        }
        
        let sessionEvent = sessionStorage.getItem('selectedEvent')
        if (sessionEvent) {
            setEvent(JSON.parse(sessionEvent))
        } else {
            getEvent(eventId)
            .then((res) => {
                setEvent(res.data)
            })
            .catch((err) => {
                console.error(err)
                hideLoading()
            })
        }

        let data = {
            ticket: cart.ticket,
            listing: cart.listing,
            ticketCount: cart.ticketCount,
            promoCode: cart.promoCode
        };

        if (cart?.ticket?.free) {
            let order = {
                cart: cart,
                paymentIntentId: "0"
            };
            createOrder(order)
                .then((res) => {
                    // Need better way to store order data
                    sessionStorage.setItem('order', JSON.stringify(res.data));
                    // Fake payment intent for free tickets
                    setClientSecret('pi_3Lj32nEjx5eLnToD1C9LQPI9_secret_ImFRee2TheUSer2BUY')
                    setStatus('successful')
                    setOrder(res.data)
                    hideLoading();
                })
                .catch((error) => {
                    hideLoading()
                    setErrorShow({
                        error: true,
                        msg: error.response?.data?.error?.message,
                        remaining: error?.response?.data?.error?.details?.ticketsRemaining
                    })
                })

        } else {
            getPaymentIntent(data)
                .then((res) => {
                    setClientSecret(res.data.client_secret);
                    setIntentId(res.data.id);
                    hideLoading()
                })
                .catch((err) => {
                    console.error(err)
                    hideLoading()
                });
        }
    }, []);

    useEffect(() => {
        // listen for order changes
    }, [order])

    const addOns = [];

    const appearance = {
        theme: 'flat',
        variables: {
            fontFamily: ' "Poppins", sans-serif',
            fontLineHeight: '1.4',
            fontSizeBase: '14px',
            fontSizeSm: '12px',
            fontWeightNormal: '500',
            borderRadius: '12px',
            colorPrimary: '#5ab6f8',
            colorBackground: '#fcfcfd',
            colorText: '#23262F',
            colorWarning: '#EF466F',
            colorDanger: '#EF466F',
            colorTextSecondary: '#777e90',
            spacingUnit: '12px',
            spacingGridRow: '24px'
        },
        rules: {
            '.Input': {
                boxShadow: '0px 0px 0px 2px #E6E8EC',
                padding: '12.250px 14.875px',
                lineHeight: 'var(--fontLineHeight)'
            },

            '.Input:focus': {
                outline: '0',
                boxShadow: '0px 0px 0px 2px var(--colorPrimary)'
            },
            '.Input:disabled, .Input--invalid:disabled': {
                color: 'lightgray'
            },
            '.Tab': {
                boxShadow: '0px 0px 0px 2px #E6E8EC',
                padding: '12.250px 14.875px',
                border: 'none'
            },
            '.Tab--selected, .Tab--selected:focus, .Tab--selected:hover': {
                border: 'none',
                boxShadow: '0px 0px 0px 2px var(--colorPrimary)',
                backgroundColor: 'var(--colorPrimary)'
            },
            '.Label': {
                fontWeight: '700',
                textTransform: 'uppercase',
                color: 'var(--colorTextSecondary)',
                marginBottom: 'var(--spacingUnit)',
                lineHeight: 'var(--fontLineHeight)'
            },

            '.Input--invalid': {
                boxShadow: '0 1px 1px 0 rgba(0, 0, 0, 0.07), 0 0 0 2px var(--colorDanger)'
            },

            '.Error': {
                marginTop: 'var(--spacingUnit)'
            }
        }
    };

    const options = {
        // passing the client secret obtained from the server
        clientSecret,
        appearance,
        loader: 'always'
    };

    if (status === 'successful') {
        const timer = document.getElementById('timer-container');
        const btns = document.querySelector('.desktop-btns');
        const nav = document.querySelector('.navbar-nav');

        toggleNavContent(show, btns, nav);
        toggleElement(timer, !show);
        document.getElementById('logo-link').style.pointerEvents = 'auto';
        if (document.getElementById('checkout-wrapper')) document.getElementById('checkout-wrapper').classList.add('confirmation-padding');
    }

    return (
        <>
            {isLoading ? (
                <PageLoadingContainer text="Securing your tickets" subtext="You will be redirected to the payment page." showLoader={false} icon="ticket" />
            ) : (
                <div className="full-height-wrapper" id="checkout-wrapper">
                    <>
                        <Row className="justify-content-between">
                            {clientSecret && (
                                <Elements stripe={stripePromise} options={options}>
                                    {status === 'checkout' && (
                                        <Checkout event={event} addOns={addOns} setStatus={setStatus} setOrder={setOrder} intentId={intentId} />
                                    )}
                                    {status === 'successful' && <PaymentConfirmation addOns={addOns} order={order} />}
                                </Elements>
                            )}
                        </Row>
                        <Modal
                            className="checkout-modal"
                            show={errorShow.error}
                            animation={false}
                            centered
                            keyboard={false}
                            backdrop="static"
                        >
                            <Modal.Body>
                                <h1 className="modal-body-heading-title">Limited Ticket Availability</h1>
                                <p>
                                    Please adjust your ticket selection <strong>{errorShow.remaining}</strong> tickets remaining.
                                </p>
                                <Stack className="btn-group-flex">
                                    <Button size="lg" onClick={() => navigate(-1)}>Go Back</Button>
                                </Stack>
                            </Modal.Body>
                        </Modal>
                    </>
                </div>
            )}
        </>
    );
}
