import { createContext } from 'react';

const LoadingContext = createContext({
    isLoading: false,
    showLoading: () => { },
    hideLoading: () => { }
});

export default LoadingContext;

