import React, { useState, useRef, useEffect, } from "react";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";

import { Seat } from '../Seat';
import { HTMLTooltip } from '../HTMLTooltip';

export default function SVGMap({
    zoomRef, isMobile, svgRef, pathMove, pathEnter,
    zoomTooltipDecider, hideTip, rows, setShouldShow,
    pathHidden, ticket, transformed, isExpanded,
    containerRef, showTooltipFromEvent, setTicket,
    removeFirstFromSeats, setRows, addToSeats, shouldShow,
}) {

    // Refs
    const htmlTipRef = useRef(null);

    // State
    const [selectedSeatId, setSelectedSeatId] = useState(null);
    const [htmlTipPosition, setHtmlTipPosition] = useState({
        vertical: 'top',
        horizontal: 'center'
    });

    // Lifecycle
    useEffect(() => {
        if (!shouldShow) {
            hideAllTips();
        };
    }, [shouldShow])


    const hideAllTips = () => {
        removeHTMLTooltip();
        hideTip();
    }

    const removeHTMLTooltip = () => {
        if (htmlTipRef.current) htmlTipRef.current.style.opacity = 0;
        // Clear this
        setTimeout(() => {
            if (htmlTipRef.current) htmlTipRef.current.style.pointerEvents = 'none';
        }, 100)
    }

    const selectSeat = (id) => {
        setRows((prev) => {
            return prev.map((list) => {
                return list.map((seat) => {
                    if (seat.id === id) {
                        return { ...seat, selected: true }
                    }
                    return seat;
                })
            })
        })
        addToSeats(id);
    }

    const unselectSeat = (id) => {
        setRows((prev) => {
            return prev.map((list) => {
                return list.map((seat) => {
                    if (seat.id === id) {
                        return { ...seat, selected: false }
                    }
                    return seat;
                })
            })
        })
        removeFirstFromSeats();
    }

    const showHTMLTooltip = (seat, id) => {
        setShouldShow(true);
        var htmlTip = htmlTipRef.current;
        const container = containerRef.current.getBoundingClientRect();
        const seatRect = seat.getBoundingClientRect();
        // Tooltip w and h
        const width = parseInt(htmlTip.clientWidth);
        const height = parseInt(htmlTip.clientHeight) + 25; // is arrow height

        // Seat center
        const seatCenterX = seatRect.left + seatRect.width / 2;
        const seatCenterY = seatRect.top;

        // Calculate positions
        const leftCalc = ((window.pageXOffset - container.left) + seatCenterX - (width / 2));
        const topCalc = ((window.pageYOffset - container.top) + seatCenterY - height);

        // Set position of tooltip arrow
        setHtmlTipPosition({ vertical: 'top', horizontal: 'center' });
        // Show tip
        htmlTipRef.current.style.opacity = 1;
        htmlTipRef.current.style.pointerEvents = 'all';
        htmlTip.style.left = leftCalc + 'px';
        htmlTip.style.top = topCalc + 'px';

        console.log("ID", id);
        setSelectedSeatId(id);
    }

    const showHTMLTooltipTwo = (event) => {
        setShouldShow(true);
        const htmlTip = htmlTipRef.current;
        const container = containerRef.current.getBoundingClientRect();
        const width = parseInt(htmlTip.clientWidth);
        const height = parseInt(htmlTip.clientHeight); // is arrow height

        // Position of the event relative to the container
        const eventX = event.clientX - container.left;
        const eventY = event.clientY - container.top;

        // Calculate available space on all sides
        const spaceLeft = eventX;
        const spaceTop = eventY;
        const spaceRight = container.width - eventX;
        const spaceBottom = container.height - eventY;

        // Calculate positions
        let leftCalc = eventX - (width / 2);
        let topCalc = eventY - height;

        // Huge:ish 'if' to determine positioning of tooltip
        // Thinking is there should always be enough space either top or bottom
        // Given that, choose top or bottom, then left, right or center
        if (spaceTop >= height) {
            // Align to top, so default position is fine
            if (spaceLeft >= (width / 2) && spaceRight >= (width / 2)) {
                // Align to center, so default position is fine
                topCalc -= 25; // Account for arrow
                setHtmlTipPosition({ vertical: 'top', horizontal: 'center' });
            } else if (spaceRight >= width) {
                // Align to right
                leftCalc = eventX;
                setHtmlTipPosition({ vertical: 'top', horizontal: 'right' });
            } else {
                // Align to left
                leftCalc = eventX - width;
                setHtmlTipPosition({ vertical: 'top', horizontal: 'left' });
            }
        } else if (spaceBottom >= height) {
            // Align to bottom
            topCalc = eventY;
            if (spaceLeft >= (width / 2) && spaceRight >= (width / 2)) {
                // Align to center, so default position is fine
                topCalc += 25; // Account for arrow
                setHtmlTipPosition({ vertical: 'bottom', horizontal: 'center' });
            } else if (spaceRight >= width) {
                // Align to right
                leftCalc = eventX;
                setHtmlTipPosition({ vertical: 'bottom', horizontal: 'right' });
            } else {
                // Align to left
                leftCalc = eventX - width;
                setHtmlTipPosition({ vertical: 'bottom', horizontal: 'left' });
            }
        }
        // Show tip
        htmlTip.style.opacity = 1;
        htmlTip.style.pointerEvents = 'all';
        htmlTip.style.left = leftCalc + 'px';
        htmlTip.style.top = topCalc + 'px';
    }

    const clickSection = (event, id) => {
        setTicket((ticket) => ({ ...ticket, id: id }));

        // If mobile and expanded, show HTML tooltip also on section click
        if (window.innerWidth <= 768 && isExpanded) {
            showHTMLTooltipTwo(event);
            return;
        }
        // Otherwise instantly show tooltip
        showTooltipFromEvent(event);
    }

    return (
        <>
            <HTMLTooltip
                htmlTipRef={htmlTipRef}
                htmlTipPosition={htmlTipPosition}
                selectSeat={selectSeat}
                selectedSeatId={selectedSeatId}
            />
            <TransformWrapper
                ref={zoomRef}
                initialScale={1}
                minScale={1}
                maxScale={10}
                wheel={{ step: 0.2 }}
                disabled={isMobile() && !isExpanded}
                panning={{ velocityDisabled: true }}
                onTransformed={(_, newScale) => transformed(_, newScale)}
                onPanningStart={() => setShouldShow(false)}>
                <TransformComponent>
                    <svg
                        ref={svgRef}
                        id="primary-svg"
                        data-component="svg"
                        aria-hidden="true"
                        viewBox={`0 0 10240 7680`}
                        style={{
                            backgroundImage: `url("https://mapsapi.tmol.io/maps/geometry/image/18/57/185770?removeFilters=ISM_Shadow&avertaFonts=true&app=CCP")`,
                            width: '100%',
                            height: '100%',
                            backgroundPositionX: 'center',
                            backgroundPositionY: 'center',
                            backgroundSize: 'contain',
                            backgroundRepeat: 'no-repeat',
                        }}
                    >
                        <g className='seats'>
                            {/* shouldDraw */}
                            {rows.map((list, index) => {
                                return (
                                    <g key={index}>
                                        {list.map((seat, i) => {
                                            return (
                                                <Seat
                                                    key={i}
                                                    selectSeat={selectSeat}
                                                    unselectSeat={unselectSeat}
                                                    setShouldShow={setShouldShow}
                                                    showHTMLTooltip={showHTMLTooltip}
                                                    seat={seat} />
                                            )
                                        })}
                                    </g>
                                )
                            })}
                        </g>
                        <g className='polygons' style={{ cursor: 'pointer' }}>
                            <path
                                className={`path ${pathHidden && 'pathInvisible'} ${ticket?.id === 4340 && 'fillselected'}`}
                                id="zoomt"
                                onClick={(event) => zoomTooltipDecider(event, 4340)}
                                onMouseOut={() => hideTip()}
                                d="M5865.300000000001,1284.65L5863.3,1289.8225806965818L5863.3,6420.0398413709645L6304.400000000001,6420.0398413709645L6304.400000000001,1284.831040324382L5865.400000000001,1284.831040324382z"></path>
                            <path
                                className={`path ${ticket?.id === 3446 && 'fillselected'}`}
                                id="tooltip"
                                onClick={(event) => clickSection(event, 3446)}
                                onMouseMove={(event) => pathMove(event)}
                                onMouseEnter={(event) => pathEnter(event)}
                                onMouseOut={() => hideTip()}
                                d="M6571.709999999999,422.1L6569.709999999999,424.1L6569.769999999999,1028.13L4614.02,1028.13L4614.01,424.11L4612.01,422.11L4071.66,422.11L4069.66,424.11L4069.67,1028.13L2081.92,1028.13L2079.9199999999996,1030.13L2079.9199999999996,1282.63L2081.92,1284.63L5863.07,1284.63L6304.42,1284.55L6304.42,6420.099999999999L5863.32,6420.099999999999L5863.32,6421.75L1152.39,6420.4800000000005L1150.39,6422.490000000001L1150.35,7281.19L1152.35,7283.19L3176.5599999999995,7282.84L3178.56,7280.84L3178.5700000000006,6676.9800000000005L5134.319999999999,6676.9800000000005L5134.3099999999995,7280.84L5136.3099999999995,7282.84L6834.8099999999995,7282.84L6836.8099999999995,7280.83L6836.280000000001,5612.9800000000005L7230.269999999999,5612.9800000000005L7232.269999999999,5610.98L7232.269999999999,5107.030000000001L9089.96,5106.9800000000005L9091.96,5104.98L9091.96,2328.09L9089.96,2326.09L7232.269999999999,2325.92L7232.269999999999,1624.0300000000002L9089.96,1623.9900000000002L9091.96,1621.9900000000002L9091.96,424.19L9089.96,422.19L6571.709999999999,422.1zM3111.46,1680.04L3111.56,6023.490000000001L5467.360000000001,6023.64L5467.459999999999,1680.29L3111.46,1680.04z"></path>
                        </g>
                    </svg>
                </TransformComponent>
            </TransformWrapper>
        </>
    )
}