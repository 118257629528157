import React from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';

import { formatShortDate, formatDateTime } from '../../../utilities/helpers';

import Stack from 'react-bootstrap/Stack';

import placeholder from '../../../assets/placeholder.png'

import './searchItem.scss';

export default function SearchItem({ data }) {
    return (
        <li className="search-item text-left">
            <Link to={`/e/${data?.seoUrl}/${data?.shortCode}`} className="d-flex gap-3">
                <img
                    width="48"
                    height="48"
                    className="event-image thumbnail"
                    alt={data?.name}
                    src={data.image?.url || placeholder}
                />
                <Stack className="details-info">
                    <p className="details-info-event-name">{data.name}</p>
                    <span className="small text-muted text-left">
                        {formatShortDate(moment(data.start), 'dateOnlyWithDay')} {data.display_start_time && (<span> @ {formatDateTime(moment(data.start), 'timeOnly')} </span>)} | {data.venue.name}
                    </span>
                </Stack>
            </Link>
        </li>
    );
}
