import React from 'react';

import Alert from 'react-bootstrap/Alert';
import Stack from 'react-bootstrap/Stack';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';

import { ActionBtns } from '../../ActionBtns';

import scanQR from '../../../assets/icons/scan-qr-code.svg';

export default function MyTickets({ order, handleClick, ticketStatus, myTickets }) {
    let tickets = order ? order.tickets : myTickets.tickets
    // Need to be able to pass ticket info to modal
    const setTicketName = (val) => {
        sessionStorage.setItem('ticketName', val)
    }
    return (
        <section>
            <div className="section-heading-md heading--flex">
                <h2>{tickets.length > 1 ? 'Tickets' : 'Ticket'} ({tickets.length})</h2>
                <ActionBtns handleClick={handleClick} ticketStatus={ticketStatus} order={order} myTickets={myTickets} />
            </div>
            <Alert variant="info">
                <img src={scanQR} width="44" height="44" alt="" />
                <div>
                    <p className="fw-semi-bold">Your phone is your ticket</p>
                    <p className="caption">
                        Login to blocktickets.xyz on your phone and go to My events and then click
                        Event details.
                    </p>
                </div>
            </Alert>
            <Stack gap={3} className="mt-4">
                {tickets && tickets.map((ticket, index) => {
                    return (<Card body className="card--light" key={index}>
                        <div className="heading--flex">
                            <Card.Title as="h5" className="normal">
                                {ticket?.name}
                            </Card.Title>
                            <Button onClick={() => { handleClick('details'); setTicketName(ticket?.name) }} variant="link">Details</Button>
                        </div>
                    </Card>)
                })
                }
            </Stack>
        </section>
    );
}
