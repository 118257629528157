import React from 'react';

export default function PurchasePolicy() {
    return (
        <div className="spacer docs-container docs">
            <span className="time-caption docs-date">Last updated: May 25, 2022</span>
            <h1 className="docs-heading">Purchase Policy</h1>
            <p className="doc-desc">
                Our goal is to make your purchasing experience easy, efficient and equitable, so we can get you on your
                way to live events as quickly as possible. The following is designed to ensure your satisfaction and
                understanding of the purchase process on the Blocktickets sites (collectively, “Site”). If you have any
                questions, please contact <a href="mailto:help@blocktickets.xyz" target="_blank">help@blocktickets.xyz</a>. Your
                purchases on our Site are subject to these terms. Please also review our Terms Conditions which govern
                your use of our Site.
            </p>

            <div className="docs-header">
                <h2 className="docs-header-title">Currency</h2>
                <p>
                    All ticket prices for events that occur in the United States are stated in U.S. Dollars. All ticket
                    prices for events that occur in Canada are stated in Canadian Dollars.
                </p>
            </div>
            <section>
                <h2 className="docs-section-heading">Payment methods</h2>
                <p>
                    We accept several methods of payment to accommodate your needs. If the event for which you are
                    buying tickets is located in the United States, Blocktickets.xyz accepts American Express, Visa,
                    MasterCard, and Discover. If the event for which you are buying tickets is located in Canada,
                    Blocktickets.xyz accepts American Express, Visa, and MasterCard.
                </p>
            </section>
            <section>
                <h2 className="docs-section-heading">Who are you buying from</h2>
                <p>
                    Blocktickets is a self-service online ticketing and event marketing application. Blocktickets acts
                    as the agent to those who are promoting or otherwise providing the events for which you purchase
                    tickets, such as museums, venues, teams, artist representatives and fan clubs, promoters and leagues
                    (collectively, “Event Providers”). When you purchase a ticket for an event that is located in the
                    United States, then Blocktickets LLC will be handling the transaction and collecting payment for the
                    Event Provider. When you purchase a ticket for an event that is located in Canada, then Blocktickets
                    Canada Ltd. will be handling the transaction and collecting payment for the Event Provider.
                </p>
                <p>
                    Tickets obtained from unauthorized sources may be lost, stolen or counterfeit, and if so are void.
                </p>
            </section>
            <section>
                <h2 className="docs-section-heading">Pricing and availability</h2>
                <p>
                    We sell tickets on behalf of Event Providers, which means we do not set the ticket prices or
                    determine seating locations or ticket availability. Seating charts reflect the general layout for
                    the venue at this time. For some events, the layout and specific seat locations may vary without
                    notice. Tickets are generally sold through several distribution points, including online, phone
                    centers and box offices. Most distribution points generally access the same ticketing system and
                    inventory. Therefore, tickets for popular events may sell out quickly. Occasionally, additional
                    tickets may be available prior to the event. However, we do not control this inventory or its
                    availability.
                </p>
            </section>
            <section>
                <h2 className="docs-section-heading">Order confirmation</h2>
                <p>
                    If you do not receive a confirmation number (in the form of a confirmation page or email) after
                    submitting payment information, or if you experience an error message or service interruption after
                    submitting payment information, it is your responsibility to confirm via your account or
                    Blocktickets customer service whether or not your order has been placed. Only you may be aware of
                    any problems that may occur during the purchase process. We will not be responsible for losses
                    (monetary or otherwise) if you assume that an order was not placed because you failed to receive
                    confirmation.
                </p>
            </section>
            <section>
                <h2 className="docs-section-heading">Number of tickets or “Ticket limits”</h2>
                <p>
                    When purchasing tickets on our Site, you are limited to a specified number of tickets for each event
                    (also known as a “ticket limit”). This ticket limit is posted during the purchase process and is
                    verified with every transaction. This policy is in effect to discourage unfair ticket buying
                    practices. We reserve the right to cancel any or all orders and tickets without notice to you if you
                    exceed the posted limits. This includes orders associated with the same name, e-mail address,
                    billing address, credit card number or other information.
                </p>
            </section>
            <section>
                <h2 className="docs-section-heading">Opening acts / Festival acts</h2>
                <p>
                    Opening acts or guests may sometimes tour with headlining performers. We are not always made aware
                    of opening acts or the length of their performances. Opening acts, as well as festival performers,
                    are subject to change or cancellation at any time without notice. No refund will be owed if an
                    opening act or festival performer is changed or canceled.
                </p>
            </section>
            <section>
                <h2 className="docs-section-heading">Canceled and rescheduled events</h2>
                <p>
                    Occasionally, concerts are canceled or postponed. Should this occur, we will attempt to contact you
                    to inform you of refund or exchange procedures for that concert. For exact instructions on any
                    canceled or postponed concert, please check the concert information online or Contact{' '}
                    <a href="mailto:help@blocktickets.xyz" target='_blank'>help@blocktickets.xyz</a>
                </p>
                <p>
                    If an event is canceled, and the ticket you purchased through the Site is eligible for a refund, we
                    will issue you a refund to the credit card that you used to purchase that ticket.
                </p>
                <p>
                    If the event was moved or rescheduled, the Event Provider may set refund limitations. Please Contact{' '}
                    <a href="mailto:help@blocktickets.xyz" target="_blank">help@blocktickets.xyz</a> for more information or to request
                    a refund if you cannot attend the rescheduled event. Please make sure to include/provide your
                    account number so we can expedite your refund if applicable.
                </p>
            </section>
            <section>
                <h2 className="docs-section-heading">Refunds and exchanges</h2>
                <p>
                    Before purchasing tickets, carefully review your event and seat selection. Policies set forth by
                    Event Providers generally prohibit us from issuing exchanges or refunds after a ticket has been
                    purchased or for lost, stolen, damaged or destroyed tickets.
                </p>
                <p>
                    You agree that you will not attempt to evade, avoid, or circumvent any refund prohibitions in any
                    manner with regard to tickets you purchased. Without limiting the generality of the foregoing, you
                    will not contact us to seek a refund or exchange from us when we are prohibited from providing one
                    by its clients, and you will not dispute or otherwise seek a “chargeback” from the company whose
                    credit card you used to purchase tickets from the Site. Should you do so, your tickets may be
                    canceled, and we may, in its sole discretion, refuse to honor pending and future ticket purchases
                    made from all credit card accounts or online accounts on which such chargebacks have been made, and
                    may prohibit all persons in whose name the credit card accounts exist and any person who accesses
                    any associated online account or credit card or who otherwise breaches this provision from using the
                    Site.
                </p>
                <p>
                    If we issue you a refund for a ticket due to a canceled or postponed event, we will issue a refund
                    of the ticket’s face value paid (or, for a discounted ticket, then instead the discounted ticket
                    price paid) and, if purchased on Blocktickets.xyz, all service fees. Service fees may not be
                    refunded for tickets purchased on Blocktickets.xyz In no event will delivery prices or any other
                    fees or amounts be refunded. If a refund is issued, it will be issued using the same method of
                    payment that was used to purchase the tickets. We will not be liable for travel or any other
                    expenses that you or anyone else incurs in connection with a canceled or postponed event.
                </p>
            </section>
            <section>
                <h2 className="docs-section-heading">Billing information verification</h2>
                <p>
                    Orders are processed only after a billing address, and other billing information, has been verified.
                    Occasionally, we receive incorrect billing or credit card account information for a ticket order
                    that can delay processing and delivery. In these cases, Blocktickets customer service will attempt
                    to contact you, using the information provided at the time of purchase. If we are unable to reach
                    you after our initial attempt, we may cancel your order and may sell your tickets to another
                    customer without further notice.
                </p>
            </section>
            <section>
                <h2 className="docs-section-heading">Pricing and other errors</h2>
                <p>
                    If the amount you pay for a ticket is incorrect regardless of whether because of an error in a price
                    posted on this Site or otherwise communicated to you, or you are able to order a ticket before its
                    scheduled on-sale or presale date or you are able to order a ticket that was not supposed to have
                    been released for sale, then we will have the right to cancel that ticket (or the order for that
                    ticket) and refund to you the amount that you paid. This will apply regardless of whether because of
                    human error or a transactional malfunction of this website or other Blocktickets operated system.
                </p>
            </section>
            <section>
                <h2 className="docs-section-heading">Multiple browser windows</h2>
                <p>
                    When ordering tickets online with us, please ensure you are looking for tickets and placing an order
                    using only one browser window. Looking up tickets using multiple browser windows could result in
                    losing your tickets or timer expiration.
                </p>
            </section>
            <section>
                <h2 className="docs-section-heading">Limitation of Liability</h2>
                <p>
                    Balls, pucks, and other objects may fly into the spectator area during an event. Despite spectator
                    shielding, injury can occur. Stay alert at all times before, during and after play or performance.
                    If struck, immediately ask an usher for directions to a medical station. You voluntarily assume all
                    risks and danger incidental to the event for which the ticket is issued, whether occurring before,
                    during or after the event, and you waive any claims for personal injury or death against us,
                    management, facilities, leagues, artists, other participants, and all of our respective parents,
                    affiliated entities, agents, officers, directors, owners and employees on behalf of yourself and any
                    accompanying minor. You bear all risks of inclement weather. Event date and time are subject to
                    change. See the Limitation of Liability section in the Terms & Conditions for additional limits on
                    our liability.
                </p>
            </section>
            <section>
                <h2 className="docs-section-heading">License; Ejection and cancellation; No redemption value</h2>
                <p>
                    You agree to comply with all of the Event Providers applicable rules, policies, terms and conditions
                    (Event Provider Rules). Event Providers reserve the right, without refund of any amount paid, to
                    refuse admission to, or eject, any person whose conduct management deems disorderly, who uses vulgar
                    or abusive language or who fails to comply with Event Provider Rules. Breach of terms or rules will
                    terminate your license to attend the event without refund. A ticket is a revocable license and
                    admission may be refused upon refunding the tickets face amount. A ticket is not redeemable for
                    cash.
                </p>
            </section>
            <section>
                <h2 className="docs-section-heading">Recording, transmission and exhibition</h2>
                <p>
                    You agree that the event for which you purchase tickets is a public event, that your appearance and
                    actions inside and outside the venue where the event occurs are public in nature, and that you have
                    no expectation of privacy with regard to your actions or conduct at the event. You grant permission
                    to us and the Event Provider(s) to utilize your name, image, likeness, acts, poses, plays,
                    appearance, movements, and statements in any live or recorded audio, video, or photographic display
                    or other transmission, exhibition, publication or reproduction made of, or at, the event (regardless
                    of whether before, during or after play or performance) for any purpose, in any manner, in any
                    medium or context now known or hereafter developed, without further authorization from, or
                    compensation to, you or anyone acting on your behalf.
                </p>
            </section>
            <section>
                <h2 className="docs-section-heading">You are subject to search</h2>
                <p>
                    You and your belongings may be searched on entry to the event. You consent to such searches and
                    waive any related claims that may arise. If you elect not to consent to such searches, you may be
                    denied entry to the event without refund or other compensation. Under certain facility rules,
                    certain items may not be brought into the premises, including without limitation, alcohol, drugs,
                    controlled substances, cameras, recording devices, laser pointers, strobe lights, irritants (e.g.,
                    artificial noisemakers), bundles and containers.
                </p>
            </section>
            <section>
                <h2 className="docs-section-heading">Unlawful resale of tickets; Promotions</h2>
                <p>
                    Unlawful resale (or attempted resale), counterfeit or copy of tickets is grounds for seizure and
                    cancellation without compensation. Certain maximum resale premiums and restrictions may apply in
                    some states and we cannot be responsible for providing legal advice regarding resale or pricing of
                    tickets. You are responsible for complying with all applicable ticket resale laws. We reserve the
                    right to restrict or deny ticket purchasing privileges to anyone that we determine to be, or has
                    been, in violation of our policies. Because we do not guarantee the authenticity of tickets
                    purchased from any non-authorized third party reseller (such as brokers or individuals), we
                    recommend that you purchase tickets directly through us, authorized partners or from the venue box
                    office to ensure ticket authenticity. Tickets may not be used for advertising, promotions, contests
                    or sweepstakes, unless formal written authorization is given by us.
                </p>
            </section>
            <section>
                <h2 className="docs-section-heading">Contact Us</h2>
                <p>If you have any questions about this Purchase Policy, You can contact us:</p>
                <ul>
                    <li>
                        By email:{' '}
                        <a href="mailto:help@blocktickets.xyz" target="_blank">
                            help@blocktickets.xyz
                        </a>
                    </li>
                </ul>
            </section>
        </div>
    );
}
