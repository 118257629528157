import React, { useEffect, useState, useContext } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import moment from 'moment';
import { Helmet } from 'react-helmet-async';

import TicketContext from '../../context/Ticket/Ticket';
import LoadingContext from '../../context/Loading/Loading';

import { getEventByShortCode, getTaxRates } from '../../utilities/api';
import { formatDateTime, capitalizeString, noTZDate } from '../../utilities/helpers';

import { Alert } from 'react-bootstrap';

import { EventTicketsWrapper } from '../../components';

export default function ShortCodePage() {

    const { showLoading, hideLoading } = useContext(LoadingContext)

    const [searchParams] = useSearchParams();

    let { shortcode, slug } = useParams();

    let code = searchParams.get('code') ? searchParams.get('code') : 0

    const [event, setEvent] = useState()

    const [hasTicketsScheduled, setHasTicketsScheduled] = useState(false);

    const [scheduledStartDate, setScheduledStartDate] = useState();

    const [hasError, setHasError] = useState(false)

    const [
        taxRates,
        setTaxRates
    ] = useState();
    const [
        feeStructure,
        setFeeStructure
    ] = useState();
    const [
        tickets,
        setTickets
    ] = useState();
    const [
        listings,
        setListings
    ] = useState();
    const [
        generalAdmissionCount,
        setGaCount
    ] = useState();
    const [
        generalAdmissionTicket,
        setGaTicket
    ] = useState();
    const [
        resaleTickets,
        setResaleTickets
    ] = useState();

    useEffect(() => {
        showLoading()
        getEventByShortCode(shortcode, slug, code)
            .then((res) => {
                if (res.data?.status === 404) {
                    hideLoading()
                    setHasError(true)
                    return
                }
                setHasError(false)
                // only set true if event has no tickets/listings available 
                const isScheduled = res.data?.scheduledCount > 0 && (res.data?.tickets.length === 0 && res.data?.listings.length === 0)
                if (isScheduled) {
                    if (res.data?.scheduledTicketsStart.length > 0) setScheduledStartDate(new Date(noTZDate(res.data.scheduledTicketsStart[0], res?.data?.timezone?.offset)))
                    setHasTicketsScheduled(isScheduled)
                }
                setFeeStructure(res.data.event.fee_structure)
                setTickets(res.data?.tickets)
                setEvent(res.data?.event)
                sessionStorage.setItem('selectedEvent', JSON.stringify(res.data?.event)) /* Need to refactor later */
                setListings(res.data?.listings)
                eventTaxRates(res.data?.event?.venue?.address[0]?.city, res.data?.event?.venue?.address[0]?.state)

            })
            .catch((err) => {
                console.error(err)
                hideLoading()
            })
    }, [])

    const eventTaxRates = (city, state) => {
        getTaxRates(city, state)
            .then((res) => {
                setTaxRates(res?.data?.sales_tax_rates[0])
                hideLoading()
            })
            .catch((err) => {
                console.error(err)
                hideLoading();
            })
    }

    return (
        <div className="full-height-wrapper">
            <TicketContext.Provider value={{ tickets, listings, generalAdmissionCount, generalAdmissionTicket, resaleTickets }}>
                {event &&
                    <Helmet>
                        <meta charSet="utf-8" />
                        <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1" />
                        <meta name="theme-color" content="#000000" />
                        <meta name="keywords" content={`${event?.name.trim()} Tickets, ${formatDateTime(moment(event?.start), 'seoDate')}, ${event?.venue?.name?.trim()} - ${capitalizeString(event?.venue?.address[0]?.city)}, ${capitalizeString(event?.venue?.address[0]?.state)}`} />
                        <title>{`Buy Ticket to ${event?.name.trim()} in ${capitalizeString(event?.venue?.address[0]?.city)} on ${formatDateTime(moment(event?.start), 'seoDate')}`}</title>
                        {/* Facebook Meta Tags */}
                        <meta property="og:url" content={`${window.location.href}`} />
                        <meta property="og:type" content="website" />
                        <meta property="og:title" content={`Buy Ticket to ${event?.name.trim()} in ${capitalizeString(event?.venue?.address[0]?.city)} on ${formatDateTime(moment(event?.start), 'seoDate')}`} />
                        <meta property="og:description" content={`${event?.name.trim()} Tickets, ${formatDateTime(moment(event?.start), 'seoDate')}, ${event?.venue?.name?.trim()} - ${capitalizeString(event?.venue?.address[0]?.city)}, ${capitalizeString(event?.venue?.address[0]?.state)}`} />
                        <meta property="og:image" content={`${event?.image?.url}`} />
                        {/* Twitter Meta Tags */}
                        <meta name="twitter:card" content="summary_large_image" />
                        <meta property="twitter:domain" content={`${window.location.host}`} />
                        <meta property="twitter:url" content={`${window.location.href}`} />
                        <meta name="twitter:title" content={`Buy Ticket to ${event?.name.trim()} in ${capitalizeString(event?.venue?.address[0]?.city)} on ${formatDateTime(moment(event?.start), 'seoDate')}`} />
                        <meta name="twitter:description" content={`${event?.name.trim()} Tickets, ${formatDateTime(moment(event?.start), 'seoDate')}, ${event?.venue?.name?.trim()} - ${capitalizeString(event?.venue?.address[0]?.city)}, ${capitalizeString(event?.venue?.address[0]?.state)}`} />
                        <meta name="twitter:image" content={`${event?.image?.url}`} />
                    </Helmet>
                }
                {event && !hasError && (
                    <EventTicketsWrapper event={event} code={code} taxRates={taxRates} feeStructure={feeStructure} hasTicketsScheduled={hasTicketsScheduled} scheduledStartDate={scheduledStartDate} />
                )}

                { hasError &&
                  <Alert className='mt-4' variant={'danger'}>The page you're looking for doesn't exist, please ensure the URL provided was correct.</Alert>
                }
            </TicketContext.Provider>
        </div>
    )
}