import React, { useState } from 'react';

import Stack from 'react-bootstrap/Stack'
import { FlipCardModal } from '../FlipCardModal';
import FlipCardModalBody from '../FlipCardModal/FlipCardModalBody';
import { FlipCard } from '../FlipCard';

export default function SecondaryFeatureCards() {

    const [showModal, setShowModal] = useState(false);
    const [modalContent, setModalContent] = useState({ title: '', text: '' });
    const toggleModal = () => {
        setShowModal(!showModal);
    }

    const cards = [
        {
            id: "1",
            front: {
                title: "Simplified Guest List."
            },
            back: {
                title: 'Manage guests.',
                text: 'Your event guest list updates in real time, and adding and removing guests from your list is as simple as a single click. Each newly added guest receives their tickets via text moments after they\'re added to your list, making their check-in experience seamless.'
            }
        },
        {
            id: "2",
            front: {
                title: "Virtual Box Office."
            },
            back: {
                title: 'Scan, Pay, Enter.',
                text: 'Transform your event entry with our Virtual Box Office. It allows event organizers to replace traditional methods by enabling fans to purchase tickets at the door with a simple QR code scan. This system ensures cashless transactions, securely managed through our platform.'
            }
        }
    ]

    const doNothing = (id) => {
        // do nothing
        console.log(id);
    }

    return (
        <section className='secondary-features'>
            <FlipCardModal modalOpen={showModal} handleClose={toggleModal} headingText={modalContent.title}>
                <FlipCardModalBody textContent={modalContent.text}></FlipCardModalBody>
            </FlipCardModal>
            <div className="info-container">
                <Stack gap={4} className='secondary-features-grid flex-lg-row justify-content-between align-items-center'>
                    {/* DEV, This is just for now, dunno if we want these to have overlay */}
                    <FlipCard toggleOverlay={doNothing} toggleModal={toggleModal} setModalContent={setModalContent} showModal={showModal} cardId={12} obj={cards[0]} variant="pale" id="guest-list" titleSize="sm" size="sm" />
                    <FlipCard toggleOverlay={doNothing} toggleModal={toggleModal} setModalContent={setModalContent} showModal={showModal} cardId={13} obj={cards[1]} variant="darker" id="mobile" titleSize="sm" size="sm" />
                </Stack>
            </div>
        </section>
    );
}
