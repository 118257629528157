import React, { useEffect, useState, useRef, useContext } from 'react';

import LoadingContext from '../../context/Loading/Loading';

import { PageLoadingContainer, Event } from '../../components';

import { PurchaseTickets } from './PurchaseTickets';

export default function EventTicketsWrapper({ event, code, taxRates, feeStructure, hasTicketsScheduled, scheduledStartDate }) {

    const { isLoading } = useContext(LoadingContext)

    const containerRef = useRef(null);
    const tooltipRef = useRef(null);

    // 1 - ticket selection
    // 2 - ticket confirmation
    // 3 - presale
    const steps = {
        selection: 1,
        confirmation: 2,
        presale: 3
    };

    const [
        step,
        setStep
    ] = useState('selection');

    // current ticket
    const [
        ticket,
        setTicket
    ] = useState();

    // current listing
    const [
        listing,
        setListing
    ] = useState();

    const [
        showFilter,
        setShowFilter
    ] = useState(false);

    const [showBack, setShowBack] = useState(false)

    // Controls when remove / show both tooltips
    const [shouldShow, setShouldShow] = useState(false);

    useEffect(() => {
        handleShowBack(step)
    }, [step, showFilter])

    const handleShowBack = step => {
        if (step === 'selection') {
            if (showFilter) {
                return setShowBack(true)
            }
            return setShowBack(false)
        } else {
            return setShowBack(true)
        }
    }

    // what is this method for - do we need in this component?
    const selectSectionSwitch = (id) => {
        switch (id) {
            case 4340:
                // This responsiveness is just for now
                if (window.innerWidth > 768 || step === 'selection') selectSection('zoomt');
                break;
            case 3446:
                if (window.innerWidth > 768 || step === 'selection') selectSection('tooltip')
                break;
            case 21888:
                if (window.innerWidth > 768 || step === 'selection') selectSection('zoomt')
                break;
            case 21922:
                if (window.innerWidth > 768 || step === 'selection') selectSection('tooltip')
                break;
            case 21910:
                if (window.innerWidth > 768 || step === 'selection') selectSection('zoomt')
                break;
            default:
                const tip = tooltipRef.current;
                tip.style.opacity = 0;
        }
    }

    // what is this method for - do we need in the component? // Handles second tooltip, no
    // if we need selectSelectionSwitch in this component, then we need this method
    // This is not actually accurate
    const selectSection = (id) => {
        setShouldShow(true);
        var target = document.getElementById(id);
        var rect = target.getBoundingClientRect();
        const tip = tooltipRef.current;
        const container = containerRef.current.getBoundingClientRect();
        const width = parseInt(tip.clientWidth);
        const height = parseInt(tip.clientHeight);
        const xPos = ((rect.left + (rect.width / 2)) - container.left) - (width / 2);
        const yPos = ((rect.top + (rect.height / 2)) - container.top) - height - 10;
        tip.style.opacity = 1;
        tip.style.left = xPos + 'px';
        tip.style.top = yPos + 'px';
    }

    // click on ticket to go to next step
    const handleClick = (step, ticket, listing) => {
        // find key
        setStep(Object.keys(steps).find((key) => key === step));
        setTicket(ticket);
        if (window.innerWidth > 768) selectSectionSwitch(ticket?.id); // No tooltip on mobile
        if (listing) setListing(listing)
    };

    const handleGoBack = () => {
        let prevStep;

        if (step !== 'presale') {
            let curStep = steps[step];
            prevStep = --curStep;
        } else {
            prevStep = 1;
        }

        // find key based on value
        setStep(Object.keys(steps).find((key) => steps[key] === prevStep));
        setListing(null)
        setTicket(null)
    };

    const handleShow = () => {
        setShowFilter(!showFilter);
    }

    const handleBack = () => {
        return showFilter ? handleShow() : handleGoBack();
    }

    return (
        <>
            {isLoading ? (
                <PageLoadingContainer />
            ) : (
                <>
                    <Event event={event} onClick={handleBack} showBack={showBack} />
                    <PurchaseTickets event={event} isScheduled={hasTicketsScheduled} code={code} taxRates={taxRates} feeStructure={feeStructure} scheduledStartDate={scheduledStartDate} ticket={ticket} setTicket={setTicket} listing={listing} step={step} handleClick={handleClick} handleGoBack={handleGoBack} handleShow={handleShow} showFilter={showFilter} containerRef={containerRef} tooltipRef={tooltipRef} shouldShow={shouldShow} setShouldShow={setShouldShow} />
                </>
            )}
        </>
    );
}
