import React from 'react';
import { SwiperSlide } from 'swiper/react';

import { Slider } from '../../Slider';
import { QRTicketCard } from '../../QRTicketCard';

export default function GuestPassesSlider({ passes, event, handleClick }) {

    return (
        <div className="page-slider">
            <Slider addedModule="pagination">
                {passes?.map((pass, index) => {
                    return (
                        <SwiperSlide key={index} className="swiper-lazy">
                            <QRTicketCard key={index} pass={pass} event={event} handleClick={handleClick} />
                        </SwiperSlide>
                    );
                })}
            </Slider>
        </div>
    );
}
