import React from 'react';

import { CookiesPolicy, Footer } from '../../components'

export default function CookiesPolicyPage() {
	return (
			<>
			    <CookiesPolicy />
                <Footer />
            </>
	);
}
