import React, { useState, useRef, useEffect } from 'react';
import * as d3 from "d3";

import Image from 'react-bootstrap/Image';
import Button from 'react-bootstrap/Button';

import { SVGMap } from './SVGMap';
import { Legend } from './Legend';
import { ZoomLevel } from './ZoomLevel';
import { FilterBtn } from '../TicketPurchase/TicketSelection/FilterBtn';

import generalAdmission from '../../../../assets/map-general_admission.svg'
import fullscreen from '../../../../assets/icons/fullscreen.svg';

import './seatingMap.scss';

const isMobile = () => {
    const [width, setWidth] = useState(window.innerWidth);
    const handleWindowSizeChange = () => {
        setWidth(window.innerWidth);
    }

    useEffect(() => {
        window.addEventListener('resize', handleWindowSizeChange);
        return () => {
            window.removeEventListener('resize', handleWindowSizeChange);
        }
    }, []);

    return (width <= 768);
}

export default function SeatingMap({
    event, styles, eventType, isExpanded, setIsExpanded, addToSeats,
    removeFirstFromSeats, selectedSeats, ticket, setTicket,
    pathEnter, showTooltipFromEvent, hideTip, pathMove,
    shouldShow, setShouldShow
}) {

    const [rows, setRows] = useState([
        [
            { cx: 6082.81, cy: 6219.19, r: 29.5, selected: false, id: 1 },
            { cx: 6082.81, cy: 6144.68, r: 29.5, selected: false, id: 2 },
            { cx: 6082.81, cy: 6070.16, r: 29.5, selected: false, id: 3 },
            { cx: 6082.81, cy: 5995.65, r: 29.5, selected: false, id: 4 },
            { cx: 6082.81, cy: 5921.13, r: 29.5, selected: false, id: 5 },
            { cx: 6082.81, cy: 5846.62, r: 29.5, selected: false, id: 6 },
            { cx: 6082.81, cy: 5772.1, r: 29.5, selected: false, id: 7 },
            { cx: 6082.81, cy: 5697.59, r: 29.5, selected: false, id: 8 },
            { cx: 6082.81, cy: 5623.07, r: 29.5, selected: false, id: 9 },
            { cx: 6082.81, cy: 5548.56, r: 29.5, selected: false, id: 10 },
            { cx: 6082.81, cy: 5474.04, r: 29.5, selected: false, id: 11 },
            { cx: 6082.81, cy: 5399.53, r: 29.5, selected: false, id: 12 },
            { cx: 6082.81, cy: 5325.01, r: 29.5, selected: false, id: 13 },
            { cx: 6082.81, cy: 5250.5, r: 29.5, selected: false, id: 14 },
            { cx: 6082.81, cy: 5175.98, r: 29.5, selected: false, id: 15 },
            { cx: 6082.81, cy: 6219.19, r: 29.5, selected: false, id: 16 }, // dis
            { cx: 6082.81, cy: 6219.19, r: 29.5, selected: false, id: 17 }, // dis
            { cx: 6082.81, cy: 4952.44, r: 29.5, selected: false, id: 18 },
            { cx: 6082.81, cy: 4877.92, r: 29.5, selected: false, id: 19 },
            { cx: 6082.81, cy: 4803.41, r: 29.5, selected: false, id: 20 },
            { cx: 6082.81, cy: 4728.89, r: 29.5, selected: false, id: 21 },
            { cx: 6082.81, cy: 4654.38, r: 29.5, selected: false, id: 22 },
            { cx: 6082.81, cy: 4579.86, r: 29.5, selected: false, id: 23 },
            { cx: 6082.81, cy: 4505.35, r: 29.5, selected: false, id: 24 },
            { cx: 6082.81, cy: 4430.83, r: 29.5, selected: false, id: 25 },
            { cx: 6082.81, cy: 4356.32, r: 29.5, selected: false, id: 26 },
            { cx: 6082.81, cy: 4281.8, r: 29.5, selected: false, id: 27 },
            { cx: 6082.81, cy: 4207.29, r: 29.5, selected: false, id: 28 },
            { cx: 6082.81, cy: 4132.77, r: 29.5, selected: false, id: 29 },
            { cx: 6082.81, cy: 4058.26, r: 29.5, selected: false, id: 30 },
            { cx: 6082.81, cy: 3983.74, r: 29.5, selected: false, id: 31 },
            { cx: 6082.81, cy: 3909.23, r: 29.5, selected: false, id: 32 },
            { cx: 6082.81, cy: 3834.71, r: 29.5, selected: false, id: 33 },
            { cx: 6082.81, cy: 3760.2, r: 29.5, selected: false, id: 34 },
            { cx: 6082.81, cy: 3685.68, r: 29.5, selected: false, id: 35 },
            { cx: 6082.81, cy: 3611.17, r: 29.5, selected: false, id: 36 },
            { cx: 6082.81, cy: 3536.65, r: 29.5, selected: false, id: 37 },
            { cx: 6082.81, cy: 3462.14, r: 29.5, selected: false, id: 38 },
            { cx: 6082.81, cy: 3387.62, r: 29.5, selected: false, id: 39 },
            { cx: 6082.81, cy: 3313.11, r: 29.5, selected: false, id: 40 },
            { cx: 6082.81, cy: 3238.59, r: 29.5, selected: false, id: 41 },
            { cx: 6082.81, cy: 3164.08, r: 29.5, selected: false, id: 42 },
            { cx: 6082.81, cy: 3089.56, r: 29.5, selected: false, id: 43 },
            { cx: 6082.81, cy: 3015.05, r: 29.5, selected: false, id: 44 },
            { cx: 6082.81, cy: 2940.53, r: 29.5, selected: false, id: 45 },
            { cx: 6082.81, cy: 2866.02, r: 29.5, selected: false, id: 46 },
            { cx: 6082.81, cy: 2791.5, r: 29.5, selected: false, id: 47 },
            { cx: 6082.81, cy: 2716.99, r: 29.5, selected: false, id: 48 },
            { cx: 6082.81, cy: 2642.47, r: 29.5, selected: false, id: 49 },
            { cx: 6082.81, cy: 2567.96, r: 29.5, selected: false, id: 50 },
            { cx: 6082.81, cy: 2493.44, r: 29.5, selected: false, id: 51 },
            { cx: 6082.81, cy: 2418.93, r: 29.5, selected: false, id: 52 },
            { cx: 6082.81, cy: 2344.41, r: 29.5, selected: false, id: 53 },
            { cx: 6082.81, cy: 2269.9, r: 29.5, selected: false, id: 54 },
            { cx: 6082.81, cy: 2195.38, r: 29.5, selected: false, id: 55 },
            { cx: 6082.81, cy: 2120.87, r: 29.5, selected: false, id: 56 },
            { cx: 6082.81, cy: 2269.9, r: 29.5, selected: false, id: 57 }, // dis
            { cx: 6082.81, cy: 2269.9, r: 29.5, selected: false, id: 58 }, // dis
            { cx: 6082.81, cy: 2269.9, r: 29.5, selected: false, id: 59 }, // dis
            { cx: 6082.81, cy: 2269.9, r: 29.5, selected: false, id: 60 }, // dis
            { cx: 6082.81, cy: 2269.9, r: 29.5, selected: false, id: 61 }, // dis
            { cx: 6082.81, cy: 1599.26, r: 29.5, selected: false, id: 62 },
            { cx: 6082.81, cy: 1524.75, r: 29.5, selected: false, id: 63 },
        ],
    ])

    const [scale, setScale] = useState(1);
    const [open, setOpen] = useState(false);
    const [shouldDraw, setShouldDraw] = useState(true);
    const [pathHidden, setPathHidden] = useState(false);

    let hidePath = useRef(false);
    let svgRef = useRef(null);
    let containerRef = useRef(null);
    let zoomRef = useRef(null);
    let prevScale = useRef(1);

    // data-section-id arbitrary dynamic number/letter combination ( seats, polygons ) prefixed with letter on seats ( prefix relates to quality of seat)
    // data-label-id arbitrary dynamic number/letter combination ( labels )
    // data-section-name based on original svg area id ( seats, polygons )

    useEffect(() => {
        setShouldShow(false);
        // Reset zoom when map is collapsed
        if (!isExpanded) {
            if (zoomRef.current) zoomRef.current.resetTransform();
        }
    }, [isExpanded])

    // Scale changes a bazillion times when interacting with the map
    // So dont do stuff every time scale changes, instead do it everytime shouldShow changes
    useEffect(() => {
        setShouldShow(false);
    }, [scale])

    const onClick = () => {
        // when clicked on the expand button to have flag to style full sized map and to expand popup
        return setIsExpanded(!isExpanded);
    };

    const zoomTooltipDecider = (event, id) => {
        if (isExpanded || window.innerWidth > 768) {
            zoomRef.current.zoomToElement("zoomt");
            // Delay until zoom is finished, then hidePath
            setTimeout(() => {
                setPathHidden(true);
                hidePath.current = true;
            }, 650)
        } else {
            // Set seat as selected if mobile
            setTicket((ticket) => ({ ...ticket, id: id }));
            // Instantly show tooltip
            showTooltipFromEvent(event);
        }
    }

    // If anything changes in the map, this triggers
    const transformed = (_, newScale) => {
        const scaleFactor = newScale.scale;
        setScale(scaleFactor);
        if (scaleFactor > 4 || (hidePath.current && scaleFactor >= prevScale.current)) {
            checkViewBoxIntersection();
            return;
        }
        setPathHidden(false);
        hidePath.current = false;
        prevScale.current = scaleFactor;
    }

    // Check if the sections are intersecting with view, if yes, draw seats
    const checkViewBoxIntersection = () => {
        setPathHidden(true);
        hidePath.current = true;
        const threshold = 1;
        // Get polygons from the svg
        const d3Svg = d3.select(svgRef.current);
        // This might get the entire polygons container instead of its children
        // Figure it out when we have data
        const svgChildren = d3Svg.selectChildren(".polygons");
        // Get container boundingClientRect
        const container = containerRef.current.getBoundingClientRect();
        const [left1, top1, right1, bottom1] = [container.left, container.top, container.right, container.bottom];
        svgChildren._groups[0].forEach((element) => {
            // Get polygon boundingClientRect
            const child = element.getBoundingClientRect();
            const [left2, top2, right2, bottom2] = [child.left, child.top, child.right, child.bottom];
            // Check if the polygon is intersecting with the section
            const intersecting =
                left2 <= right1 - threshold &&
                top2 <= bottom1 - threshold &&
                bottom2 >= top1 - threshold &&
                right2 >= left1 - threshold;
            const noSeats = d3.select(".seats").selectChild("g").selectChild("g").empty();
            // If intersecting && contains no seats, draw seats
            if (intersecting && noSeats) {
                setShouldDraw(true);
            };
        })
    };

    return (
        <div ref={containerRef} className={`map align-items-center justify-content-around ${styles && styles} ${isExpanded ? 'full-height-mobile' : 'set-height'}`} style={{ backgroundImage: `url(${event?.image?.url})` }}>
            {eventType === 'genAdmission' ? (
                <div className="with-blur-backdrop">
                    <img src={event?.image?.url} alt={`Cover art for ${event?.name}`} width="360"
                        height="360" className='event-image' />
                </div>
            ) : (
                <>
                    <SVGMap
                        zoomRef={zoomRef}
                        isMobile={isMobile}
                        pathMove={pathMove}
                        pathEnter={pathEnter}
                        zoomTooltipDecider={zoomTooltipDecider}
                        svgRef={svgRef}
                        rows={rows}
                        setShouldShow={setShouldShow}
                        pathHidden={pathHidden}
                        ticket={ticket}
                        transformed={transformed}
                        isExpanded={isExpanded}
                        shouldShow={shouldShow}
                        containerRef={containerRef}
                        showTooltipFromEvent={showTooltipFromEvent}
                        setTicket={setTicket}
                        setRows={setRows}
                        addToSeats={addToSeats}
                        hideTip={hideTip}
                        removeFirstFromSeats={removeFirstFromSeats}
                    />
                    {/* only show zoomlevel and legend if on desktop */}
                    {!isMobile() && (
                        <div className='controls-container'>
                            <Legend />
                            <ZoomLevel zoomRef={zoomRef} scale={scale} />
                        </div>
                    )}
                    {/* only show legend and filter if map is expanded and and on mobile */}
                    {isMobile() && isExpanded && (
                        <div className={`controls-container ${selectedSeats?.length > 0 ? 'with-ticket-popup' : ''}`}>
                            <Legend />
                            <FilterBtn />
                        </div>
                    )}
                    <Button variant='outline-light' className='expand' onClick={() => onClick()}>
                        <Image src={fullscreen} alt="expand" />
                    </Button>
                </>
            )}
        </div>
    );
}