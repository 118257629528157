import React, { useState } from 'react';

import { BackButton } from '../../../../../BackButton';
import { PriceRangeSlider } from '../PriceRangeSlider';

import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Stack from 'react-bootstrap/Stack';
import ToggleButtonGroup from 'react-bootstrap/ToggleButtonGroup';
import ToggleButton from 'react-bootstrap/ToggleButton';

import './filterMenu.scss';

export default function FilterMenu({
    handleShow,
    show,
    sliderValues,
    setSliderValues,
    originalValues,
    showResale,
    ticketFilters,
    setTicketFilters,
    eventType
}) {

    const isGenAdmission = eventType === 'genAdmission'

    const [filters, setFilters] = useState(ticketFilters)

    const [
        on,
        setOn
    ] = useState(false);

    const handleChecked = (e) => {
        const { name } = e.target;

        setFilters((prevState) => ({
            ...prevState,
            [e.target.name]: !prevState[name]
        }));
    };

    const handleChange = (val) => {
        setFilters((prevState) => ({
            ...prevState,
            sortBy: val
        }))
    }

    const handleFilters = () => {
        setTicketFilters(filters)
        handleShow(!show)
    };

    return (
        <Stack className={`filter-panel ${show && 'filter-panel--open'}`}>
            <Stack className='filter-container'>
                <header>
                    <div className="tablet-desktop-only">
                        <BackButton handleGoBack={handleShow} marginBottom={4} />
                    </div>
                    <h3 className="normal text-uppercase m-0">Filters</h3>
                    {!isGenAdmission && (
                        <>
                            <span className='form-label d-block mt-3'>Sort List By</span>
                            <ToggleButtonGroup
                                type="radio" name='btn-rad' className='mb-1'
                                onChange={handleChange}>
                                <ToggleButton id='tbg-btn-rad-1' className="flex-grow-1 mt-0" variant="default" value='lowest' checked={filters.sortBy === 'lowest'}
                                    active={filters.sortBy === 'lowest'}>
                                    Lowest price
                                </ToggleButton>
                                <ToggleButton id='tbg-btn-rad-2' className="flex-grow-1 mt-0" variant="default"
                                    value='best'
                                    checked={filters.sortBy === 'best'}
                                    active={filters.sortBy === 'best'}
                                >
                                    Best seats
                                </ToggleButton>
                            </ToggleButtonGroup>
                        </>
                    )}

                    {/* Disabling until we have more ticket types */}
                    {/* <Form.Check
						type="checkbox"
						label="Show prices with fees"
						id="fees" 
						name="showFees"
						onChange={handleChecked}
						checked={ticketFilters.showFees}
					/> */}
                </header>
                <div className="full-height-container">
                    <div className="scrollable-area">
                        {/* {!isGenAdmission && (
                            <PriceRangeSlider
                                sliderValues={sliderValues}
                                setSliderValues={setSliderValues}
                                originalValues={originalValues}
                            />
                        )} */}
                        <legend>
                            <p className="caption--uppercase text-muted legend-heading">Ticket Type</p>
                            <ul>
                                <li>
                                    <Form.Check
                                        type="checkbox"
                                        id="standard"
                                        onChange={handleChecked}
                                        checked={filters.standard}
                                        name="standard"
                                        label="Standard Ticket"
                                    />
                                </li>
                                {showResale && (
                                    <li>
                                        <Form.Check
                                            type="checkbox"
                                            onChange={handleChecked}
                                            checked={filters.resale}
                                            id="resale"
                                            name="resale"
                                            label="Verified Resale Ticket"
                                        />
                                    </li>
                                )}
                            </ul>
                        </legend>
                        <legend>
                            <Stack direction="horizontal" className="heading--flex">
                                <p className="small legend-heading accessibility">Accessibility</p>
                                <Form.Switch
                                    onChange={() => setOn(!on)}
                                    value={on}
                                    id="custom-switch"
                                    aria-label="Toggle Accessibility"
                                />
                            </Stack>
                            <div className="accessibility-body">
                                {on && (
                                    <ul>
                                        <li>
                                            <Form.Check
                                                type="checkbox"
                                                id="impairment"
                                                label="Vision and/or hearing impairment"
                                            />
                                        </li>
                                        <li>
                                            <Form.Check
                                                type="checkbox"
                                                id="wheelchair"
                                                label="Wheelchair Accessible"
                                            />
                                        </li>
                                    </ul>
                                )}
                            </div>
                        </legend>
                    </div>
                </div>
                <Stack className="footer">
                    <Button variant="primary" size="lg" onClick={handleFilters}>
                        Apply Filters
                    </Button>
                </Stack>
            </Stack>
        </Stack>
    );
}
