import React, { useState } from 'react';
import { Link } from 'react-router-dom'

import Card from 'react-bootstrap/Card';
import Stack from 'react-bootstrap/Stack';

import { PopupCard } from '../../../../PopupCard'
import { MySeatsHeader } from '../TicketSelection/MySeatsHeader';
import { MySeats } from '../TicketSelection/MySeats';

import './ticketPurchasePopupCard.scss';

export default function TicketPurchasePopupCard({ amount, selectedSeats, ticketCount, maxQuantity, canExpand }) {

    const [
        expanded,
        setExpanded
    ] = useState(false);

    return (
        <div className="sticky">
            <PopupCard amount={amount} ticketCount={ticketCount} canExpand={canExpand} expanded={expanded} setExpanded={setExpanded}>
                {expanded && (
                    <Card.Body className='scrollable-area'>
                        <MySeatsHeader maxQuantity={maxQuantity} />
                        <Stack as="ul" gap={1}>
                            <MySeats selectedSeats={selectedSeats} />
                        </Stack>
                    </Card.Body>
                )}
                <Card.Footer>
                    <Link to={'/checkout'} className="btn w-100 btn-primary btn-lg">
                        Checkout
                    </Link>
                </Card.Footer>
            </PopupCard>
        </div>
    )
}
