import React, { useLayoutEffect, useState, useEffect, useRef } from 'react';

import { fullHeightContainer, removeFullHeightContainer } from '../../../utilities/helpers';

import { SeatingMap } from './SeatingMap';
import { TicketPurchase } from './TicketPurchase';

import './purchaseTickets.scss';

export default function PurchaseTickets({ event, isScheduled, code, taxRates, feeStructure, scheduledStartDate, ticket, setTicket, listing, step, handleClick, handleGoBack, handleShow, showFilter, containerRef, tooltipRef, shouldShow, setShouldShow }) {

    // clicks the expand map button; flag to style map full screen on mobile and whether to expand popup
    const [isExpanded, setIsExpanded] = useState(false)

    // seats that are clicked on the map; use to check whether to show MySeats and popup
    const [selectedSeats, setSelectedSeats] = useState([])

    const timer = useRef(null);
    const initialTimestamp = useRef(null); // can this go in SeatingMap? - does this control the map when hovering over ticket?
    const eventMousePos = useRef({ x: 0, y: 0 }); // can this go in SeatingMap? - does this control the map when hovering over ticket?

    useLayoutEffect(
        () => {
            const el = document.querySelector('#main-container');
            fullHeightContainer(el)

            return () => {
                removeFullHeightContainer(el)
            }
        }, []);

    useEffect(() => {
    }, [taxRates, feeStructure])


    // flag to display different elements based map type - will come from admin when creating an event - events will have type attribute 
    const eventType = window.location.host !== 'development.blocktickets.xyz' ? "genAdmission" : "";

    // Update eventMousePos on mousemove - can this go in SeatingMap? - this controls the map when hovering over ticket
    const pathMove = (event) => {
        if (!(initialTimestamp.current && event.timeStamp - initialTimestamp.current > 300)) {
            eventMousePos.current = { x: event.pageX, y: event.pageY };
        }
    }

    // On enter start countdown to show tooltip - can this go in SeatingMap? - this controls the map when hovering over ticket
    const pathEnter = (event) => {
        if (!initialTimestamp.current) {
            initialTimestamp.current = event.timeStamp;
            timer.current = setTimeout(() => showTooltipFromState(), 300);
        }
    }

    // can this go in SeatingMap? - this controls the map when hovering over ticket
    const showTooltipFromState = () => {
        showTooltip(eventMousePos.current.x, eventMousePos.current.y);
    }

    // can this go in SeatingMap? - this controls the map when hovering over ticket
    const showTooltipFromEvent = (event) => {
        showTooltip(event.pageX, event.pageY);
    }

    // can this go in SeatingMap? - this controls the map when hovering over ticket
    const showTooltip = (x, y) => {
        // No tooltip on mobile
        if (window.innerWidth <= 768) return;
        const tip = tooltipRef.current;
        const container = containerRef.current.getBoundingClientRect();
        const width = parseInt(tip.clientWidth);
        const height = parseInt(tip.clientHeight);
        const xPos = (x - container.left) - (width / 2);
        const yPos = (y - container.top) - height - 10;
        tip.style.opacity = 1;
        tip.style.left = xPos + 'px';
        tip.style.top = yPos + 'px';
        initialTimestamp.current = null; // Reset
    }
    // can this go in SeatingMap? - this controls the map when hovering over ticket?
    const hideTip = () => {
        clearTimeout(timer.current);
        initialTimestamp.current = null;
        // Then hide tooltip
        setShouldShow(false)
        tooltipRef.current.style.opacity = 0;
        setTicket((ticket) => ({ ...ticket, id: 0 }));
    }

    const addToSeats = (seat) => {
        setSelectedSeats([...selectedSeats, seat]);
    }

    const removeFirstFromSeats = () => {
        setSelectedSeats(selectedSeats.slice(1));
    }

    return (
        <div ref={containerRef} className="pt-md-3 flex d-flex flex-column flex-md-row" style={{ position: 'relative' }}>
            <SeatingMap
                event={event}
                styles={(step === 'confirmation' || step === 'presale') && 'tablet-desktop-only'}
                isExpanded={isExpanded}
                setIsExpanded={setIsExpanded}
                addToSeats={addToSeats}
                removeFirstFromSeats={removeFirstFromSeats}
                selectedSeats={selectedSeats}
                eventType={eventType}
                ticket={ticket}
                hideTip={hideTip}
                showTooltipFromEvent={showTooltipFromEvent}
                pathEnter={pathEnter}
                pathMove={pathMove}
                setTicket={setTicket}
                step={step}
                shouldShow={shouldShow}
                setShouldShow={setShouldShow}
            />

            {/* does this have to be in this component? */}
            <div ref={tooltipRef} className={`purchaseTooltip ${(step === 'confirmation' || step === 'presale') && 'hidden'}`} style={{ opacity: 0 }}>
                <p>Sec B. Row 2</p>
                <p>34 Tickets</p>
            </div>

            <TicketPurchase
                isScheduled={isScheduled}
                selectedSeats={selectedSeats}
                scheduledStartDate={scheduledStartDate}
                handleClick={handleClick}
                handleGoBack={handleGoBack}
                handleShow={handleShow}
                showFilter={showFilter}
                step={step}
                isExpanded={isExpanded}
                eventType={eventType}
                ticket={ticket}
                listing={listing}
                code={code}
                taxRates={taxRates}
                feeStructure={feeStructure}
            />
        </div>
    );
}
