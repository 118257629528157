import React from 'react';
import { useParams } from 'react-router-dom';

import { InvoiceWrapper } from '../../../../components'

export default function InvoicePage() {

    const { id } = useParams();

    return (
        <InvoiceWrapper id={id} />
    );
}
