import React, { Fragment } from 'react';
import moment from 'moment';

import { formatDateTime, getStartDateFormatter, formatAddress, getTicket } from '../../../utilities/helpers'

import Modal from 'react-bootstrap/Modal';
import ListGroup from 'react-bootstrap/ListGroup';

export default function DetailsModal({ order, guestList, myTickets }) {

    const obj = order ? order || guestList : myTickets;
    const ticketName = sessionStorage.getItem('ticketName')

    return (
        <Fragment>
            <Modal.Header closeButton>
                <Modal.Title as="h4">Details</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="modal-body-heading--with-border d-flex d-flex-column pb-2 mb-0 details">
                    <h4 className="modal-body-heading-title mb-2">{obj?.event?.name}</h4>
                    {obj?.event?.presentedBy && (<span className='presenter-text'>{obj?.event?.presentedBy}</span>)}
                    <ul>
                        <li><span>{formatDateTime(moment(obj?.event?.start), getStartDateFormatter(obj?.event))}</span> {!obj?.event?.hide_end_date && (<span>- {formatDateTime(moment(obj?.event?.end), 'timeOnly')}</span>)}</li>
                        {!obj?.event?.hide_doors_open && (
                            <li>Doors Open at {formatDateTime(moment(obj?.event?.doorsOpen), 'timeOnly')}</li>
                        )}
                    </ul>
                </div>
                <ListGroup as="ul" variant="flush">
                    {/* <ListGroup.Item as="li" className="list">
                        <p className="heading">Entry Info</p>
                        <span>GA</span>
                    </ListGroup.Item> */}
                    <ListGroup.Item as="li" className="list">
                        <p className="heading">Ticket type</p>
                        <span>{order ? getTicket(obj, 'name') : ticketName}</span>
                    </ListGroup.Item>
                    <ListGroup.Item as="li" className="list">
                        <p className="heading">Location</p>
                        <ul>
                            <li>{obj?.event?.venue?.name}</li>
                            <li>{formatAddress(obj?.event?.venue)}</li>
                        </ul>
                    </ListGroup.Item>
                    {obj?.orderId && (
                        <ListGroup.Item as="li" className="list">
                            <p className="heading">Order Number</p>
                            <span>{obj?.orderId}</span>
                        </ListGroup.Item>
                    )}
                    <ListGroup.Item as="li" className="list">
                        <p className="heading">Purchase Date</p>
                        <span>{formatDateTime(moment(obj?.processedAt || obj?.createdAt))}</span>
                    </ListGroup.Item>
                    {/* <ListGroup.Item as="li" className="list">
                        <p className="heading">Contact Address</p>
                        <span className="text-secondary">Oxa7d8…..d270</span>
                    </ListGroup.Item>
                    <ListGroup.Item as="li" className="list">
                        <p className="heading">Token ID</p>
                        <span className="text-secondary">23000121</span>
                    </ListGroup.Item>
                    <ListGroup.Item as="li" className="list">
                        <p className="heading">Token Standard</p>
                        <span className="text-secondary">ERC - 721</span>
                    </ListGroup.Item>
                    <ListGroup.Item as="li" className="list">
                        <p className="heading">Blockchain</p>
                        <span className="text-secondary">Ethereum</span>
                    </ListGroup.Item> */}
                </ListGroup>
            </Modal.Body>
        </Fragment>
    );
}
