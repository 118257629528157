import React, { useState, useLayoutEffect, useEffect, useContext } from 'react';

import authService from '../../utilities/services/auth.service';
import LoadingContext from '../../context/Loading/Loading';

import { getGuestList } from '../../utilities/api';
import { fullHeightContainer, removeFullHeightContainer, userDevice } from '../../utilities/helpers';

import { PageLoadingContainer } from '../PageLoadingContainer';
import { BackButton } from '../BackButton';
import { Event } from '../Event';
import { GuestPassesSlider } from './GuestPassesSlider';
import { GuestPasses } from './GuestPasses'
import { ActionBtns } from '../ActionBtns';
import { TicketModal } from '../TicketModal';

export default function GuestPassDetailsWrapper({ eventId }) {

    const { isLoading, showLoading, hideLoading } = useContext(LoadingContext)

    const { getUser } = authService;
    const user = getUser().user;

    const [guestList, setGuestList] = useState([])

    const [
        show,
        setShow
    ] = useState(false);

    const [
        ticketAction,
        setTicketAction
    ] = useState('');

    const [ticketStatus, setTicketStatus] = useState('')

    const [deviceType, setDeviceType] = useState("");

    useLayoutEffect(() => {
        const el = document.querySelector('#main-container');

        fullHeightContainer(el);

        return () => {
            removeFullHeightContainer(el);
        };
    }, []);

    useEffect(() => {
        showLoading()
        getGuestList(user.phoneNumber, user.email)
            .then((res) => {
                setGuestList(res.data.find(d => d.eventId === eventId))
                hideLoading()
            })
            .catch((err) => {
                console.error(err)
                hideLoading()
            })

        setDeviceType(userDevice)
    }, [])

    const handleShow = () => setShow(true);

    const handleClick = (action) => {
        handleShow();
        setTicketAction(action);
    };

    return (
        <>
            {isLoading ? (
                <PageLoadingContainer />
            ) : (
                <section className="spacer-xs full-height-wrapper">
                    <div className="section-heading-sm">
                        <h1>{deviceType && deviceType === 'Mobile' ? 'Tickets' : 'Event details'}</h1>
                        <BackButton />
                    </div>
                    {deviceType && deviceType === 'Mobile' && (
                        <>
                            <GuestPassesSlider passes={guestList?.guest_passes} event={guestList?.event} handleClick={handleClick} />
                        </>
                    )}
                    {deviceType && deviceType === 'Desktop' && (
                        <>
                            <Event event={guestList?.event} />
                            <GuestPasses passes={guestList?.guest_passes} handleClick={handleClick} />
                        </>
                    )}
                    <TicketModal
                        ticketAction={ticketAction}
                        setTicketStatus={setTicketStatus}
                        show={show}
                        setShow={setShow}
                        guestList={guestList}
                    />
                </section>
            )}
        </>
    )
}