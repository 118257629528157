import React from 'react'

import Button from 'react-bootstrap/Button';

export default function HTMLTooltip({ htmlTipRef, htmlTipPosition, selectSeat, selectedSeatId }) {

    return (
        <div ref={htmlTipRef} className={`htmlTooltip ${htmlTipPosition.vertical + '--' + htmlTipPosition.horizontal}`}>
            <div className='htmlTop'>Standard Admission</div>
            <div className='htmlBody'>
                <p className='htmlPrice'>$30.00</p>
                <div className='htmlRow'>
                    <div className='htmlSection'>
                        <p className='htmlPh'>Section</p>
                        <p className='htmlP'>B</p>
                    </div>
                    <div className='htmlSection'>
                        <p className='htmlPh'>Row</p>
                        <p className='htmlP'>2</p>
                    </div>
                    <div className='htmlSection'>
                        <p className='htmlPh'>Seat</p>
                        <p className='htmlP'>16</p>
                    </div>
                </div>
                <Button variant="secondary" size="lg" className='htmlButton' onClick={() => selectSeat(selectedSeatId)}>Add now</Button>
            </div>
        </div>
    )
}