import React, { Fragment } from 'react';

import Stack from 'react-bootstrap/Stack';
import ToggleButtonGroup from 'react-bootstrap/ToggleButtonGroup';
import ToggleButton from 'react-bootstrap/ToggleButton';

export default function DisplayTickets({ role, status, tickets, selectedTickets, setSelectedTickets }) {

    // tickets should be tickets or selectedTickets depending on role
    tickets = role == 'select' ? tickets : selectedTickets

    const handleChange = (ids) => {
        setSelectedTickets(tickets?.filter(ticket => ids.includes(ticket.id)));
    }

    return (
        <Fragment>
            {role === 'select' && (
                <h6 className="modal-body-heading-title mb-4">Select tickets to {status}</h6>
            )}
            <Stack direction="horizontal" className="split-row">
                <div>
                    <span className="small fw-medium">{tickets[0]?.name}</span>
                </div>
                <div className='flex-shrink-0'>
                    <span className="num-tickets">{tickets?.length} {tickets?.length > 1 ? 'Tickets' : 'Ticket'} </span>
                </div>
            </Stack>
            {role === 'select' ? (
                <ToggleButtonGroup
                    type="checkbox"
                    value={selectedTickets?.map(ticket => ticket.id)}
                    onChange={handleChange}
                    className="flex-wrap">
                    {
                        tickets && tickets.map((ticket, index) => {
                            return (<ToggleButton className="ticket-pick" id={`tbg-btn-${index}`} variant="default" name={`tbg-btn-${index}`} value={ticket?.id} key={index}>
                                {ticket.name.replaceAll('Tickets', '')}
                            </ToggleButton>
                            )
                        })
                    }
                </ToggleButtonGroup>
            ) : (
                <Stack direction="horizontal" className="btn-group flex-wrap">
                    {tickets && tickets.map((ticket, index) => {
                        return (
                            <div className="btn btn-primary ticket-pick selected" key={index}>{ticket.name.replaceAll('Tickets', '')}</div>
                        )
                    })}

                </Stack>
            )}
        </Fragment>
    );
}
