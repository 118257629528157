import React, { Fragment, useState, useEffect, useContext, useRef } from 'react';

import TicketContext from '../../../../../context/Ticket/Ticket';

import Stack from 'react-bootstrap/Stack';
import Form from 'react-bootstrap/Form';

import { PriceRangeSlider } from './PriceRangeSlider';
import { FilterBtn } from './FilterBtn';
import { FilterMenu } from './FilterMenu';
import { Ticket } from './Ticket';
import { MySeatsHeader } from './MySeatsHeader';
import { MySeats } from './MySeats';
import { TicketPurchasePopupCard } from '../TicketPurchasePopupCard';
import { NotAvailableMessage } from './NotAvailableMessage';
import { Countdown } from './Countdown';

import './ticketSelection.scss';

export default function TicketSelection({ isScheduled, selectedSeats, handleClick, handleShow, showFilter, ticketId, isExpanded, setTicketCount, ticketCount, taxRates, feeStructure, scheduledStartDate, eventType }) {

    const tickets = useContext(TicketContext);
    const scrollableRef = useRef(null);

    const [
        ticketFilters,
        setTicketFilters
    ] = useState({ sortBy: 'lowest', showFees: false, standard: true, resale: true });

    const [
        sliderValues,
        setSliderValues
    ] = useState([0, 50]);

    const [firstVisibleElement, setFirstVisibleElement] = useState(null);

    const [
        originalValues,
        setOriginalValues
    ] = useState([0, 50]);

    // min total ticket count of all standard tickets 
    const [minTotalTicketCount, setMinTotalTicketCount] = useState(1)

    // max total ticket count of all standard tickets 
    const [maxTotalTicketCount, setMaxTotalTicketCount] = useState(8)

    // flag for displaying no filtered matches 
    // set to 0 when no ticket types are selected otherwise ticket count when filters are on/off
    const [
        filteredTicketCount,
        setFilteredTicketCount
    ] = useState(1);

    // general admission ticket count 
    const [
        gaTicketsAvailable,
        setGaTicketsAvailable
    ] = useState(0)

    // general admission tickets - don't use 
    const [
        gaTickets,
        setGaTickets
    ] = useState({})

    // all tickets - used to display tickets
    const [
        availableTickets,
        setAvailableTickets
    ] = useState({})

    // tickets for resale - don't use 
    const [
        resaleTickets,
        setResaleTickets
    ] = useState({})

    // tickets listed for sale - used to display tickets 
    const [
        listings,
        setListings
    ] = useState({})

    const [showGa, setShowGa] = useState(true);

    useEffect(() => {
        const availableCountGA = parseInt(tickets?.tickets?.filter((ticket) => ticket.generalAdmission === true)?.reduce((accumulator, object) => {
            return accumulator + (object.availableCount);
        }, 0));

        const gaTickets = tickets?.tickets?.filter((ticket) => ticket.generalAdmission === true)

        handleTotalTicketCount(tickets?.tickets)
        setGaTicketsAvailable(availableCountGA)
        setGaTickets(gaTickets)
        setAvailableTickets(sortBy(tickets?.tickets))
        setResaleTickets(tickets?.reSaleTickets)
        setListings(sortBy(tickets.listings, true));
        if (!tickets?.tickets) return;

        // if no resale tickets turn off resale ticket type in filters
        if (tickets?.listings?.length === 0) {
            setTicketFilters((prevState) => ({
                ...prevState,
                resale: false
            }));
        }

        // let higestResalePrice;

        // if (tickets?.listings && tickets?.listings.length > 0) {
        //     higestResalePrice = tickets?.listings?.map(listing => listing.askingPrice + listing.tickets[0].fee).reduce((a, b) => Math.max(a, b))
        // }
        // let higestPrice = tickets?.listings && tickets?.listings?.length > 0 ? higestResalePrice : tickets.generalAdmissionTicket?.attributes?.cost + tickets.generalAdmissionTicket?.attributes?.fee + tickets.generalAdmissionTicket?.attributes?.facilityFee;
        // let lowestPrice = tickets.generalAdmissionTicket?.attributes?.cost + tickets.generalAdmissionTicket?.attributes?.fee + tickets.generalAdmissionTicket?.attributes?.facilityFee;
        let lowestPrice = Math.min.apply(Math, tickets?.tickets.map(function (o) { return o.cost; }));
        let higestPrice = Math.max.apply(Math, tickets?.tickets.map(function (o) { return o.cost; }));

        setSliderValues([lowestPrice, higestPrice])
        setOriginalValues([lowestPrice, higestPrice])
    }, [tickets, gaTicketsAvailable]);

    useEffect(
        () => {
            // if no ticket type is selected, display filter message 
            if (!tickets || !tickets.listings) return;

            // if there are ticket types selected
            if (ticketFilters.standard || ticketFilters.resale) {

                // set total ticket quantity when filtering
                // always base total ticket quantity off of available tickets
                handleTotalTicketCount(tickets?.tickets)

                // filter tickets based on selected ticket quantity 
                // always filter the available tickets where min ticket quantity is less than selected ticket quantiy and only filter resale tickets when turned on 
                setAvailableTickets(sortBy(tickets?.tickets.filter(ticket => ticket.minimum_quantity <= ticketCount)))
                // quantity is equal to selected ticket quantity
                ticketFilters.resale && setListings(sortBy(tickets?.listings.filter(ticket => ticket.quantity === ticketCount)))

                // always take the ticket count of the filtered available tickets but only add the filtered resale tickets if turned on 
                setFilteredTicketCount([tickets?.tickets.filter(ticket => ticket.minimum_quantity <= ticketCount).length, ticketFilters.resale && tickets?.listings?.filter(ticket => ticket.quantity === ticketCount).length].reduce((acc, cur) => acc + cur, 0));
            }

            // if no ticket types selected
            else if (!ticketFilters.standard && !ticketFilters.resale) {
                handleTotalTicketCount(tickets?.tickets)
                setFilteredTicketCount(0)
            }
        },
        [
            sliderValues, ticketCount, ticketFilters
        ]
    );

    // set total ticket count filtered or not 
    const handleTotalTicketCount = arr => {
        let property;

        // only resale tickets 
        if (!ticketFilters.standard && ticketFilters.resale) {
            arr = tickets?.listings;
            property = 'quantity'
        }
        const minTotalTicketCount = Math.min.apply(Math, arr?.map(ticket => ticket[property || 'minimum_quantity']));

        const maxTotalTicketCount = Math.max.apply(Math, arr?.map(ticket => ticket[property || 'maximum_quantity']));

        setMinTotalTicketCount(minTotalTicketCount)
        setMaxTotalTicketCount(maxTotalTicketCount)

        // sync up filtered ticket count with max ticket count 
        setFilteredTicketCount(maxTotalTicketCount)
    }

    // sort tickets from least expensive to most expensive 
    // unlock tickets are sorted last  
    const sortBy = (arr, isListing) => {
        // Determine the key for sorting based on the listing type
        const sortKey = isListing ? 'askingPrice' : 'cost';

        // Initialize arrays to store sorted and locked tickets
        const sortedAndLockedTickets = arr.reduce(
            (result, ticket) => {
                if (ticket?.locked) {
                    result.locked.push(ticket);
                } else {
                    result.sorted.push(ticket);
                }
                return result;
            },
            { sorted: [], locked: [] }
        );

        // Define a sorting function based on the specified key
        const sortFunction = (a, b) => a[sortKey] - b[sortKey];

        // Sort the arrays of sorted and locked tickets
        const sortedTickets = sortedAndLockedTickets.sorted.sort(sortFunction);
        const lockedTickets = sortedAndLockedTickets.locked.sort(sortFunction);

        // Combine and return the sorted array
        return [...sortedTickets, ...lockedTickets];
    }

    const selectOptions = () => {
        let options = [];
        for (let i = minTotalTicketCount; i <= maxTotalTicketCount; i++) {
            options.push({ key: i, value: i, name: i === 1 ? `${i} Ticket` : `${i} Tickets` })
        }
        return options;
    }

    // set next step
    const handleNext = (ticket, listing) => {
        if (ticket?.locked) {
            // Sets step to presale
            handleClick('presale', ticket)
        }

        else {
            handleClick('confirmation', ticket, listing)
        }
    }

    useEffect(() => {
        if (!scrollableRef.current || window.innerWidth >= 992) return;

        const container = scrollableRef.current;
        container.addEventListener('scroll', handleScroll);

        return () => container.removeEventListener('scroll', handleScroll);
    }, [scrollableRef.current])

    const handleScroll = (event) => {
        const container = document.getElementById('tickets_ul');
        const scrollContainer = event.target;
        const containerRect = scrollContainer.getBoundingClientRect();
        const firstVisible = [...container.children].findIndex(
            (child) => {
                const childRect = child.getBoundingClientRect();
                return childRect.top >= containerRect.top + 40;
            }
        );
        handleClick('selection', availableTickets[firstVisible - 1], null);
    }

    return (
        <Fragment>
            {/* if there are tickets or listing tickets */}
            {(gaTicketsAvailable && gaTicketsAvailable >= 1) || (listings && listings.length > 0) ? (
                <Fragment>
                    <header id="ticket-selection-header">
                        <Stack direction="horizontal" gap={2} className="option-btns">
                            <Form.Select
                                aria-label="Number of Tickets"
                                value={ticketCount}
                                onChange={(e) => setTicketCount(parseInt(e.target.value))}>
                                {selectOptions().map((o) => {
                                    return <option value={o.key} key={o.key}>{o.name}</option>
                                })}
                            </Form.Select>
                            <FilterBtn handleShow={handleShow} />
                        </Stack>
                        {/* {eventType !== 'genAdmission' && !isExpanded && (
                            <PriceRangeSlider
                                styles="tablet-desktop-only pb-0 pt-4"
                                sliderValues={sliderValues}
                                setSliderValues={setSliderValues}
                                originalValues={originalValues}
                            />
                        )} */}
                    </header>
                    <Stack>
                        {showFilter && (
                            <FilterMenu show={showFilter} handleShow={handleShow} sliderValues={sliderValues} setSliderValues={setSliderValues} originalValues={originalValues}
                                showResale={listings.length > 0}
                                ticketFilters={ticketFilters} setTicketFilters={setTicketFilters}
                                eventType={eventType} />
                        )}
                        {filteredTicketCount > 0 ? (
                            <>
                                {!showFilter && (
                                    <>
                                        {selectedSeats?.length > 0 && (
                                            <MySeatsHeader maxQuantity={maxTotalTicketCount} />
                                        )}
                                        <div className="full-height-container">
                                            <div className="scrollable-area" ref={scrollableRef}>
                                                <Stack as="ul" gap={1} className='tickets' id="tickets_ul">
                                                    {selectedSeats?.length === 0 ? (
                                                        <>
                                                            {availableTickets && ticketFilters.standard && availableTickets?.map((ticket, index) => (
                                                                <Ticket onMouseOver={handleClick}
                                                                    ticket={ticket} ticketId={ticketId} handleNext={handleNext} ticketFilters={ticketFilters} key={index} taxRates={taxRates} feeStructure={feeStructure}
                                                                    hasScroll={eventType !== 'genAdmission'} />
                                                            ))}
                                                            {listings && ticketFilters.resale && listings.map((listing, index) => <Ticket onMouseOver={handleClick} key={index} handleNext={handleNext} ticketFilters={ticketFilters} listing={listing} taxRates={taxRates} feeStructure={feeStructure}
                                                                hasScroll={eventType !== 'genAdmission'} />
                                                            )}
                                                        </>
                                                    ) : (
                                                        <MySeats selectedSeats={selectedSeats} />
                                                    )}
                                                </Stack>
                                            </div>
                                        </div>
                                        {/* only show if seats are selected */}
                                        {/* expand popup only on is map is expanded - can only be expanded on mobile */}
                                        {selectedSeats?.length > 0 && (
                                            <TicketPurchasePopupCard selectedSeats={selectedSeats} amount="30" ticketCount={1} canExpand={isExpanded} maxQuantity={maxTotalTicketCount} />
                                        )}
                                    </>
                                )}
                            </>
                        ) : (
                            <>
                                {!showFilter && (
                                    <NotAvailableMessage>
                                        <h1 className="normal">Please adjust your search</h1>
                                        <p>
                                            The seating options you selected aren't available due to the ticket
                                            quantity or filter you applied. Please try adjusting the number of
                                            tickets selected or use the seat map to search for available seats.
                                        </p>
                                    </NotAvailableMessage>
                                )}
                            </>
                        )
                        }
                    </Stack>

                </Fragment>
            ) : (
                <>
                    {isScheduled ? (
                        <Countdown scheduledStartDate={scheduledStartDate} />
                    ) : (
                        <NotAvailableMessage>
                            <h1 className="fs-md">Sorry, tickets are sold out.</h1>
                            <p>Please check back anytime later to see if new tickets appear</p>
                        </NotAvailableMessage>
                    )}
                </>
            )}

        </Fragment>
    );
}