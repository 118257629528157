import { downloadApplePass } from "../../../utilities/api"
import addToAppleWalletSVG from '../../../assets/icons/add-to-apple-wallet-logo.svg'
import Button from 'react-bootstrap/Button';


export default function ApplePassButton({ event, obj }) {
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;
    const isIphone = /iPhone/i.test(userAgent);
    const downloadAppleWalletPass = async () => {
        const payload = {
            event,
            obj
        }
        const response = await downloadApplePass(payload);

        // Iw all went well, download the pkpass or pkpasses file/'s
        if (response.status === 200) {
            let contentType = response.headers['content-type'];
            const blob = new Blob([response.data], { type: contentType });
            console.log('blob: ', blob);
            console.log(`Received Blob: Type = ${blob.type}, Size = ${blob.size}`); // Log blob type and size
              
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.style.display = 'none';
            a.href = url;
            a.download = 'event.pkpass';
            document.body.appendChild(a);
            a.click();
            // Clean up
            setTimeout(() => {
                window.URL.revokeObjectURL(url);
                document.body.removeChild(a);
            }, 100); 
        } else {
          console.error('Failed to download the pass.');
        }
    }

    if (!isIphone) return null;

    return (
        <Button onClick={downloadAppleWalletPass} variant="info" id="apple-wallet-btn" aria-label="Add to Apple Wallet" className="mt-0">
            <img src={addToAppleWalletSVG} alt="add to apple wallet" />
        </Button>
    )
}