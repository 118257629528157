import React, { useRef, useLayoutEffect, useEffect, useState, useContext } from 'react';

import LoadingContext from '../../context/Loading/Loading';
import authService from '../../utilities/services/auth.service';

import { getMyTransfers } from '../../utilities/api';
import { fullHeightContainer, removeFullHeightContainer } from '../../utilities/helpers';

import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';

import { PageLoadingContainer } from '../PageLoadingContainer';
import { EmptyContainer } from '../EmptyContainer';
import { SwiperNavigationButtons } from '../SwiperNavigationButtons';
import { MyTransfersSlider } from './MyTransfersSlider';
import { TicketModal } from '../TicketModal';

import './myTransfersWrapper.scss';

export default function MyTransfersWrapper() {

    const user = authService.getUser()

    const { isLoading, showLoading, hideLoading } = useContext(LoadingContext)

    const navigationPrevRef = useRef(null);
    const navigationNextRef = useRef(null);

    const [
        key,
        setKey
    ] = useState('pending');

    const [
        show,
        setShow
    ] = useState(false);

    const [
        ticketAction,
        setTicketAction
    ] = useState('');

    const [
        transfers,
        setTransfers
    ] = useState();

    const [
        transfer,
        setTransfer
    ] = useState('');

    useLayoutEffect(() => {
        const el = document.querySelector('#main-container');
        const body = document.body;

        fullHeightContainer(el);
        body.classList.add('noBodyPadding');

        return () => {
            removeFullHeightContainer(el);
            body.classList.remove('noBodyPadding');
        };
    }, []);

    useEffect(() => {
        showLoading()
        getTransfers();
    }, []);

    const getTransfers = () => {
        getMyTransfers()
            .then((res) => {
                setTransfers(res.data)
                hideLoading()
            }).catch((err) => {
                console.error(err)
                hideLoading()
            });
    };

    const handleShow = () => setShow(true);

    const handleClick = (action, transfer) => {
        setTransfer(transfer);
        handleShow();
        setTicketAction(action);
    };

    return (
        <>
            {isLoading ? (
                <PageLoadingContainer />
            ) : (
                <section className="spacer-xs" id="my-transfers-wrapper">
                    <div className="section-heading-sm">
                        <h1>My transfers</h1>
                        <div className="tablet-desktop-only">
                            <SwiperNavigationButtons navigationPrevRef={navigationPrevRef} navigationNextRef={navigationNextRef} />
                        </div>
                    </div>
                    <div className="d-flex-column position-relative">
                        <Tabs defaultActiveKey="pending" variant="pills" activeKey={key} onSelect={(k) => setKey(k)}>
                            <Tab eventKey="pending" title="Pending">
                                {transfers && transfers.filter(transfer => transfer.status === 'pending' && transfer.event !== null && transfer.phoneNumberToUser !== user.user.phoneNumber).length > 0 ? (
                                    <MyTransfersSlider
                                        navigationPrevRef={navigationPrevRef}
                                        navigationNextRef={navigationNextRef}
                                        transfers={transfers.filter((transfer) => transfer.status === 'pending' && transfer.event !== null && transfer.phoneNumberToUser !== user.user.phoneNumber)}
                                        cancel={handleClick}
                                    />
                                ) : (
                                    <EmptyContainer>
                                        <h1>You do not have any {key} transfers</h1>
                                    </EmptyContainer>
                                )}
                            </Tab>
                            <Tab eventKey="completed" title="Completed">
                                {transfers && transfers.filter((transfer) => ((transfer.status === 'claimed' || transfer.status === 'cancelled') && (transfer.event !== null && transfer.phoneNumberToUser !== user.user.phoneNumber))).length > 0 ? (
                                    <MyTransfersSlider
                                        navigationPrevRef={navigationPrevRef}
                                        navigationNextRef={navigationNextRef}
                                        transfers={transfers.filter((transfer) => ((transfer.status === 'claimed' || transfer.status === 'cancelled') && (transfer.event !== null && transfer.phoneNumberToUser !== user.user.phoneNumber))
                                        )}
                                    />
                                ) : (
                                    <EmptyContainer>
                                        <h1>You do not have any {key} transfers</h1>
                                    </EmptyContainer>
                                )}
                            </Tab>
                        </Tabs>
                    </div>

                    <TicketModal
                        ticketAction={ticketAction}
                        show={show}
                        setShow={setShow}
                        transfer={transfer}
                        getMyTransfers={getTransfers}
                    />
                </section>
            )}
        </>
    );
}
