import React from 'react';

import Stack from 'react-bootstrap/Stack';

export default function MySeatssHeader({ maxQuantity }) {
    return (
        <>
            <Stack direction="horizontal" className="heading--flex mt-2">
                <span className="ticket-limit">Event ticket limit: {maxQuantity}</span>
            </Stack>
        </>
    );
}
