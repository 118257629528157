import React from 'react';

import './spinner.scss';

export default function Spinner({ variant = 'default', size }) {
    let color;
    let width;
    let height;

    switch (variant) {
        case 'dark':
            color = '#777e90';
            break;

        // page and component loading spinner
        case 'primary':
            color = '#3E8BF7';
            break;

        default:
            color = '#fcfcfd';
            break;
    }

    if (size == 'lg') {
        width = '20';
        height = '20';
    }
    else {
        (width = '13'), (height = '13');
    }
    return (
        <svg
            id="spinner-icon"
            width={width}
            height={height}
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
                d="M10 0C9.44771 0 9 0.447715 9 1V4C9 4.55228 9.44771 5 10 5C10.5523 5 11 4.55228 11 4V1C11 0.447715 10.5523 0 10 0Z"
                fill={color}
            />
            <path
                d="M10 15C9.44771 15 9 15.4477 9 16V19C9 19.5523 9.44771 20 10 20C10.5523 20 11 19.5523 11 19V16C11 15.4477 10.5523 15 10 15Z"
                fill={color}
            />
            <path
                d="M19 9C19.5523 9 20 9.44771 20 10C20 10.5523 19.5523 11 19 11H16C15.4477 11 15 10.5523 15 10C15 9.44771 15.4477 9 16 9H19Z"
                fill={color}
            />
            <path
                d="M5 10C5 9.44771 4.55228 9 4 9H1C0.447715 9 0 9.44771 0 10C-1.19209e-07 10.5523 0.447715 11 1 11H4C4.55228 11 5 10.5523 5 10Z"
                fill={color}
            />
            <path
                d="M15.6569 2.92903C16.0474 2.53851 16.6806 2.53851 17.0711 2.92903C17.4616 3.31955 17.4616 3.95272 17.0711 4.34324L14.9498 6.46456C14.5593 6.85509 13.9261 6.85509 13.5356 6.46456C13.1451 6.07404 13.1451 5.44088 13.5356 5.05035L15.6569 2.92903Z"
                fill={color}
            />
            <path
                d="M6.46443 13.5355C6.07391 13.145 5.44074 13.145 5.05022 13.5355L2.9289 15.6568C2.53837 16.0473 2.53837 16.6805 2.9289 17.071C3.31942 17.4615 3.95259 17.4615 4.34311 17.071L6.46443 14.9497C6.85496 14.5592 6.85496 13.926 6.46443 13.5355Z"
                fill={color}
            />
            <path
                d="M17.0711 15.6569C17.4616 16.0474 17.4616 16.6806 17.0711 17.0711C16.6806 17.4616 16.0474 17.4616 15.6569 17.0711L13.5356 14.9498C13.145 14.5593 13.145 13.9261 13.5356 13.5356C13.9261 13.1451 14.5592 13.1451 14.9498 13.5356L17.0711 15.6569Z"
                fill={color}
            />
            <path
                d="M6.46452 6.46443C6.85505 6.07391 6.85505 5.44074 6.46452 5.05022L4.3432 2.9289C3.95268 2.53837 3.31951 2.53837 2.92899 2.9289C2.53847 3.31942 2.53847 3.95259 2.92899 4.34311L5.05031 6.46443C5.44083 6.85495 6.074 6.85495 6.46452 6.46443Z"
                fill={color}
            />
        </svg>
    );
}
