import React, { Fragment } from 'react';
import { Helmet } from 'react-helmet-async';

import { HomeWrapper, Footer } from '../../components';

export default function HomePage() {

    return (
        <Fragment>
            <Helmet>
                <meta charSet="utf-8" />
                <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1" />
	            <meta name="theme-color" content="#000000" />
                <title>Event experiences that go beyond tickets. | Blocktickets</title>
                {/* Facebook Meta Tags */}
                <meta property="og:url" content={`${window.location.host}`} />
                <meta property="og:type" content="website" />
                <meta property="og:title" content={"Event experiences that go beyond tickets. | Blocktickets"} />
                <meta property="og:description" content={`We combine digital collectibles with tickets to control secondary market revenue, eliminate ticket fraud, and build community between event organizers, artists, and fans like never before.`} />
                <meta property="og:image" content={`https://blocktickets.nyc3.cdn.digitaloceanspaces.com/logo.png`} />
                {/* Twitter Meta Tags */}
                <meta name="twitter:card" content="summary_large_image" />
                <meta property="twitter:domain" content={`${window.location.host}`} />
                <meta property="twitter:url" content={`${window.location.host}`} />
                <meta name="twitter:title" content={`Event experiences that go beyond tickets. | Blocktickets`} />
                <meta name="twitter:description" content={`We combine digital collectibles with tickets to control secondary market revenue, eliminate ticket fraud, and build community between event organizers, artists, and fans like never before.`} />
                <meta name="twitter:image" content={`https://blocktickets.nyc3.cdn.digitaloceanspaces.com/logo.png`} />
            </Helmet>
            <main>
                <HomeWrapper />
            </main>
            <Footer />
        </Fragment>
    );
}
