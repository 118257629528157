import React from 'react';

import { PrivacyPolicy, Footer } from '../../components'

export default function PrivacyPolicyPage() {
	return (
			<>
			    <PrivacyPolicy />
                <Footer />
            </>
	);
}
