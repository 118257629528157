import React from 'react';

import Stack from 'react-bootstrap/Stack';

import { Spinner } from './Spinner';

import './loadingContainer.scss';

// loading spinner - used by page loading and data loading 
export default function LoadingContainer({ children }) {

    return (
        <div id="loading-container">
            <Stack className='justify-content-center align-items-center' gap={2}>
                <Spinner variant="primary" size="lg" />
                {children}
            </Stack>
        </div>
    );
}
