import React from 'react';

import { formatCurrency } from '../../utilities/helpers';

import Card from 'react-bootstrap/Card';
import Stack from 'react-bootstrap/Stack';
import Button from 'react-bootstrap/Button';

import './popupCard.scss';

export default function PopupCard({ children, amount, ticketCount, canExpand, expanded, setExpanded }) {

    return (
        <Card className={`total-card card-xl card--light card--popup ${expanded && 'card--popup-expanded'}`}>
            <Card.Header className="heading--flex">
                <Stack gap={1}>
                    <Card.Title as="h5">
                        Total
                    </Card.Title>
                    <h6 className='caption fw-normal'>{ticketCount} {ticketCount > 1 ? 'Tickets' : 'Ticket'}</h6>
                </Stack>
                <Stack direction="horizontal" gap={2} className="card-header-price align-items-start">
                    <span className="fw-semi-bold fs-md">{formatCurrency(amount)}</span>
                    {canExpand && (
                        <Button
                            onClick={() => setExpanded(!expanded)}
                            variant="outline-light"
                            className=" btn--icon btn--icon-sm">
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path
                                    fillRule="evenodd"
                                    clipRule="evenodd"
                                    d="M16.2071 9.79289C15.8166 9.40237 15.1834 9.40237 14.7929 9.79289L12 12.5858L9.20711 9.79289C8.81658 9.40237 8.18342 9.40237 7.79289 9.79289C7.40237 10.1834 7.40237 10.8166 7.79289 11.2071L11.2929 14.7071C11.6834 15.0976 12.3166 15.0976 12.7071 14.7071L16.2071 11.2071C16.5976 10.8166 16.5976 10.1834 16.2071 9.79289Z"
                                    fill="#777E91"
                                />
                            </svg>
                        </Button>
                    )}
                </Stack>
            </Card.Header>
            {children}
        </Card>
    )
}
