import React, { useState, useEffect } from 'react';

import { confirmCode } from '../../../../../utilities/api';

import Stack from 'react-bootstrap/Stack';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';

import { BackButton } from '../../../../BackButton';
import { Spinner } from '../../../../LoadingContainer/Spinner';

import './ticketPresale.scss';

export default function TicketPresale({ ticket, handleGoBack, handleClick }) {

    const [code, setCode] = useState('')

    const [invalidCode, setInvalidCode] = useState()

    const [isSaving, setIsSaving] = useState(false)

    useEffect(() => {
        setInvalidCode('')
    }, [code])


    const confirmAccessCode = (ticket, code) => {
        setIsSaving(true)
        let data = {}
        data['accessCode'] = code;
        data['ticket'] = ticket
        confirmCode(data)
            .then((res) => {
                console.log(res);
                if (res.data) {
                    console.log(res.data);
                    handleClick('confirmation', ticket)
                    setIsSaving(false)
                } else {
                    // Invalid error would go here
                    setInvalidCode('Invalid access code. Please try again')
                    setIsSaving(false)
                }
            })
            .catch((err) => {
                console.error(err)
                setIsSaving(false)
            })
    }

    return (
        <>
            <header>
                <div className="tablet-desktop-only">
                    <BackButton handleGoBack={handleGoBack} marginBottom="2" />
                </div>
            </header>
            <Stack gap={3} id="ticket-presale" className="pb-3 justify-content-end">
                <div>
                    <h1 className="normal">
                        Get early access to this event.
                    </h1>
                    <p className='small text-muted'>Enter the access code to unlock this ticket.</p>
                </div>
                <div>
                    <Form.Control
                        type="text"
                        aria-label='Enter access code'
                        placeholder="Enter access code"
                        value={code}
                        onChange={(e) => setCode(e.target.value)}
                        className={`text-uppercase ${invalidCode ? 'error-border' : ''}`}
                    />
                    {invalidCode && (
                        <Form.Text className='text-danger'>{invalidCode}</Form.Text>
                    )}
                </div>

                <Stack direction="horizontal" className="btn-group-flex">
                    <Button
                        onClick={() => confirmAccessCode(ticket, code)}
                        size="lg"
                        className='icon-button'
                        id="btn-unlock"
                        disabled={!code || invalidCode || isSaving}
                    >
                        {isSaving ? (
                            <Spinner />
                        ) :
                            'Unlock'
                        }
                    </Button>
                </Stack>
            </Stack>
        </>
    );
}
