import React from 'react';

import { TermsConditions, Footer } from '../../components'

export default function TermsConditionsPage() {
	return (
			<>
			    <TermsConditions />
                <Footer />
            </>
	);
}
